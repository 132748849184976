import styles from "./index.module.css";
import { RadioGroup } from "@headlessui/react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import {
  useChooseDefaultCardMutation,
  useMyCardsQuery,
} from "src/app/api/payments";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Skeleton } from "src/app/components/skeleton";
import { path } from "src/app/routes/path";
import { A } from "src/app/components/a";
import { AddCardForm } from "src/app/pages/my-profile/my-payments/add-card-form";
import { Spacer } from "src/app/components/spacer";
import { useHasWalletPayment } from "src/app/hooks/use-has-wallet-payment";
import { ID_PAYMENT_METHOD, IdPaymentMethod } from "src/app/const";

type Props = {
  onChange: (
    paymentMethod: IdPaymentMethod,
    details?: { cardId?: string },
  ) => void;
  onClose: () => void;
};

export const PaymentMethods = ({ onChange, onClose }: Props) => {
  const { wallets } = useHasWalletPayment();

  return (
    <div>
      <CardList
        onClose={onClose}
        onChange={(cardId) => onChange(ID_PAYMENT_METHOD.CARD, { cardId })}
      />
      {(!!wallets?.google || !!wallets?.apple) && (
        <RadioGroup
          defaultValue={null}
          onChange={() => onChange(ID_PAYMENT_METHOD.EXPRESS_CHECKOUT)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <RadioGroup.Option value="" className={styles.radioOption}>
            {({ checked }) => (
              <>
                <div className={styles.radio}>
                  {checked ? (
                    <FaCheckCircle size={20} />
                  ) : (
                    <FaRegCircle size={20} />
                  )}
                </div>
                <RadioGroup.Label as="p">
                  Pay with apple/google
                </RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      )}
      <Spacer size={24} />
      <div>
        <p style={{ color: "#676767" }}>
          If you want to add a new Debit or Credit card, you are going to be
          directed to the payment method page on your account, leaving the
          checkout.
        </p>
        <Spacer size={24} />
        <A
          to={path.profile.payments}
          onClick={onClose}
          className={styles.addCreditCard}
        >
          + Add a debit/credit card
        </A>
      </div>
    </div>
  );
};

export const CardList = ({
  onChange,
  onClose,
}: {
  onClose: () => void;
  onChange: (cardId: string) => void;
}) => {
  const { data: myCards = [], isLoading } = useMyCardsQuery();

  const chooseDefaultCardMutation = useChooseDefaultCardMutation();

  if (isLoading)
    return <Skeleton.Rect style={{ borderRadius: "8px" }} height={90} />;

  if (!myCards.length)
    return (
      <AddCardForm
        className={styles.stepCardForm}
        onError={onClose}
        onSuccess={() => console.log("add card")}
      />
    );

  const userCardDefault = myCards.find((el) => !!el.is_selected);

  return (
    <RadioGroup
      defaultValue={userCardDefault?.id_payment_method}
      onChange={(cardId) => {
        onChange(cardId);
        chooseDefaultCardMutation.mutate(cardId);
      }}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {myCards.map((card) => (
        <RadioGroup.Option
          key={card.id_payment_method}
          value={card.id_payment_method}
          className={styles.radioOption}
        >
          {({ checked }) => (
            <>
              <div className={styles.radio}>
                {checked ? (
                  <FaCheckCircle size={20} />
                ) : (
                  <FaRegCircle size={20} />
                )}
              </div>
              <div>
                <RadioGroup.Label as="p">
                  <span style={{ fontWeight: 600 }}>
                    {capitalizeFirstLetter(card.brand)}
                  </span>
                  &nbsp;
                  <span>ends in</span>&nbsp;
                  <span>{card.last4}</span>
                </RadioGroup.Label>
                <p>
                  <span>{card.exp_month}</span>
                  <span>/</span>
                  <span>{card.exp_year}</span>
                </p>
              </div>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
