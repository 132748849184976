import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSingleAuctionQuery } from "src/app/api/auctions";
import {
  PurchaseMethod,
  useAddEnprimeurToWaitingListMutation,
  useAddToArchiveMutation,
  useToggleWishlistMutation,
  useEditABidMutation,
  useMakeABidMutation,
  useRemoveEnprimeurToWaitingListMutation,
} from "src/app/api/mutations_new_to_rename";
import { Auction, isAuctionOfLot } from "src/app/models/auctions";
import { useAlertPopup } from "../alert-popup/store";
import { useMyUser } from "src/app/hooks/useMyUser";
import { ErrorBoundary } from "@sentry/react";
import { parseApiError } from "src/app/api/_parse-api-error";
import useAuctionStatus from "src/app/hooks/useAuctionStatus";
import { ID_AUCTION_TYPE, IdPaymentMethod } from "src/app/const";
import { getAuctionMessage } from "./locale";
import { useBraze } from "../braze/use-braze";
import ConfirmPaymentModal from "./lg/atoms.form/components/confirm-payment-modal";
import ConfirmBidModal from "./lg/atoms.form/components/confirm-bid-modal";

const CardCtx = createContext<null | {
  auction: Auction;
  emp: number;
  handleBid: () => void;
  handleBuyNow: () => void;
  handleAddEnprimeurToWaitingList: () => void;
  handleRemoveEnprimeurToWaitingList: () => void;
  inView: boolean;
  isArchiveLoading: boolean;
  isArchived: boolean;
  hasEditableBid: boolean;
  isEditLoading: boolean;
  isMakeABidLoading: boolean;
  isEnprimeurWaitingListLoading: boolean;
  isWishLoading: boolean;
  isWished: boolean;
  makeABid: () => void;
  outerMostRef: (node?: Element | null | undefined) => void;
  price: number;
  quantity: number;
  refetchAuction: () => void;
  setPrice: Dispatch<SetStateAction<number>>;
  setQuantity: Dispatch<SetStateAction<number>>;
  setSlideIndex: Dispatch<SetStateAction<number>>;
  showLotProductList: boolean;
  slideIndex: number;
  toggleArchive: () => void;
  toggleIsWished: () => void;
  toggleProductList: () => void;
}>(null);

type Props = {
  initialAuction: Auction;
  children: ReactNode;
};

function CardCtxProviderWithoutBoundary({ initialAuction, children }: Props) {
  const alertPopup = useAlertPopup();
  const user = useMyUser();

  const braze = useBraze();

  const {
    data: auction,
    refetch: refetchAuction,
    outerMostRef,
    inView,
  } = useSingleAuctionQuery(initialAuction, { initialData: initialAuction });

  if (!auction) {
    throw new Error("No auction found");
  }

  const [purchaseMethodShown, showPurchaseMethod] = useState<
    PurchaseMethod | undefined
  >();
  const [quantity, setQuantity] = useState(auction.initialQuantity);
  const [price, setPrice] = useState(auction.initialPrice);
  const [showProductList, setShowProductList] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const hasEditableBid = !auction.canBid && auction.canEdit;

  const { isEnabledMaxBid, isSingleBid } = useAuctionStatus({ auction, price });

  useEffect(() => {
    if (
      !auction.options.bids.includes(price) &&
      isAuctionOfLot(auction) &&
      auction.isOfSingleLot
    ) {
      setPrice(auction.initialPrice);
    }
  }, [auction, price]);

  const archiveMutation = useAddToArchiveMutation({
    onSettled: () => refetchAuction(),
  });

  const wishlistMutation = useToggleWishlistMutation({
    onSettled: () => refetchAuction(),
  });

  const makeABidMutation = useMakeABidMutation({
    onSettled() {
      showPurchaseMethod(undefined);
      refetchAuction().then(({ data }) => {
        if (data) setPrice(data.initialPrice);
      });
    },
    onSuccess(_, { buy_now }) {
      return alertPopup.success(
        getAuctionMessage({
          messagesObject: auction.isForStore ? "shop" : "auction",
          messageType: "success",
          idAuctionType: auction.type.id,
          idPaymentMethod: user.method_payment?.active
            .id_method_payment as IdPaymentMethod,
          typeProduct: auction.type_product,
          additionalKeys: buy_now
            ? ["buy-now"]
            : isAuctionOfLot(auction) &&
                isEnabledMaxBid &&
                auction.isOfSingleLot
              ? [isSingleBid ? "place_bid" : "increase_bid"]
              : undefined,
        }),
      );
    },
    onError(error: any) {
      const isOutbidError = error.response?.data?.code === 441;
      alertPopup.error({
        title: isOutbidError ? "Offer submitted" : "Offer not submitted",
        content: parseApiError(error),
        iconStyles: isOutbidError ? { color: "#ffc107" } : {},
      });
    },
  });

  const editABidMutation = useEditABidMutation({
    onSuccess: (data) => {
      data.warnings[0]
        ? alertPopup.error({
            title: "Bid not Edited",
            content: data.warnings[0],
          })
        : alertPopup.success({ title: "Bid Edited with Success" });
    },
    onError(error) {
      alertPopup.error({
        title: "Bid not Edited",
        content: parseApiError(error),
      });
    },
    onSettled() {
      showPurchaseMethod(undefined);
      refetchAuction();
    },
  });

  const addEnprimeurToWaitingListMutation =
    useAddEnprimeurToWaitingListMutation({
      onSuccess: () => {
        refetchAuction();
        alertPopup.success(
          getAuctionMessage({
            messagesObject: "shop",
            messageType: "success",
            idAuctionType: ID_AUCTION_TYPE.EN_PRIMEUR,
            additionalKeys: ["add-waiting-list"],
          }),
        );
      },
      onError: (error) => {
        refetchAuction();
        alertPopup.error({
          title: "Error on adding auction to Waitlist",
          content: parseApiError(error),
        });
      },
    });

  const removeEnprimeurToWaitingListMutation =
    useRemoveEnprimeurToWaitingListMutation({
      onSuccess: () => {
        refetchAuction();
        alertPopup.success(
          getAuctionMessage({
            messagesObject: "shop",
            messageType: "success",
            idAuctionType: ID_AUCTION_TYPE.EN_PRIMEUR,
            additionalKeys: ["remove-waiting-list"],
          }),
        );
      },
      onError: (error) => {
        refetchAuction();
        alertPopup.error({
          title: "Error on removing auction to Waitlist",
          content: parseApiError(error),
        });
      },
    });

  function handleRemoveEnprimeurToWaitingList() {
    if (!auction || auction.type.id !== ID_AUCTION_TYPE.EN_PRIMEUR) return;

    return alertPopup.prompt({
      ...getAuctionMessage({
        messagesObject: "shop",
        messageType: "prompt",
        idAuctionType: ID_AUCTION_TYPE.EN_PRIMEUR,
        additionalKeys: ["remove-waiting-list"],
      }),
      actionButton: {
        label: "Yes",
        onClick: () => {
          alertPopup.close();
          removeEnprimeurToWaitingListMutation.mutate({
            id_auction: auction.id_auction,
            id_user: user.id,
          });
        },
      },
      closeButton: {
        label: "No, Cancel",
      },
    });
  }

  function handleAddEnprimeurToWaitingList() {
    if (!auction || auction.type.id !== ID_AUCTION_TYPE.EN_PRIMEUR) return;

    return alertPopup.prompt({
      ...getAuctionMessage({
        messagesObject: "shop",
        messageType: "prompt",
        idAuctionType: ID_AUCTION_TYPE.EN_PRIMEUR,
        additionalKeys: ["add-waiting-list"],
      }),
      actionButton: {
        label: "Yes",
        onClick: () => {
          alertPopup.close();
          addEnprimeurToWaitingListMutation.mutate({
            id_auction: auction.id_auction,
            id_user: user.id,
          });
        },
      },
      closeButton: {
        label: "No, Cancel",
      },
    });
  }

  function showBuyNowDialog() {
    showPurchaseMethod("buy_now");
  }

  function showBidDialog() {
    if (!auction) {
      throw new Error("No auction found. Please refresh your page");
    }

    showPurchaseMethod(hasEditableBid ? "edit_bid" : "place_bid");
  }

  const handleSubmitBid = () => {
    if (hasEditableBid && auction.myLastPendingBid?.bid_id) {
      return editABidMutation.mutate({
        bid_id: auction.myLastPendingBid.bid_id,
        price,
        quantity,
        type_product: auction.type_product,
      });
    } else if (!hasEditableBid) {
      return makeABidMutation.mutate({
        price,
        quantity,
        auction,
      });
    } else {
      console.error("Error submitting bid");
      refetchAuction();
    }
  };

  return (
    <CardCtx.Provider
      value={{
        auction,
        emp: auction.crurated_estimated_market_price * quantity,
        // editBid,
        handleBid: showBidDialog,
        handleBuyNow: showBuyNowDialog,
        handleAddEnprimeurToWaitingList,
        handleRemoveEnprimeurToWaitingList,
        inView,
        isArchiveLoading: archiveMutation.isLoading,
        isArchived: auction.isArchive,
        isEditLoading: editABidMutation.isLoading,
        isMakeABidLoading: makeABidMutation.isLoading,
        isEnprimeurWaitingListLoading:
          addEnprimeurToWaitingListMutation.isLoading ||
          removeEnprimeurToWaitingListMutation.isLoading,
        isWishLoading: wishlistMutation.isLoading,
        isWished: auction.isWishlist,
        hasEditableBid,
        makeABid: () => makeABidMutation.mutate({ auction, price, quantity }),
        outerMostRef,
        price,
        quantity,
        refetchAuction,
        setPrice,
        setQuantity,
        setSlideIndex,
        showLotProductList: showProductList,
        slideIndex,
        toggleArchive: () => archiveMutation.mutate(auction),
        toggleIsWished: () => wishlistMutation.mutate(auction),
        toggleProductList: () => setShowProductList((x) => !x),
      }}
    >
      {children}
      {purchaseMethodShown === "buy_now" ? (
        <ConfirmPaymentModal
          title="Choose your payment method"
          content={
            getAuctionMessage({
              messagesObject: auction.isForStore ? "shop" : "auction",
              messageType: "prompt",
              idAuctionType: auction.type.id,
              idPaymentMethod: user.method_payment?.active
                .id_method_payment as IdPaymentMethod,
              typeProduct: auction.type_product,
              additionalKeys: ["buy-now"],
            }).content
          }
          onConfirm={() =>
            makeABidMutation.mutate({
              price: auction.buy_now_price as number,
              quantity,
              auction,
              buy_now: true,
            })
          }
          onClose={() => {
            showPurchaseMethod(undefined);
            braze.logCustomEvent("Abandoned_purchase");
          }}
          bidDetails={{
            price: auction.buy_now_price as number,
            quantity,
          }}
          auction={auction}
          showTaxDisclaimer
        />
      ) : purchaseMethodShown === "place_bid" ||
        purchaseMethodShown === "edit_bid" ? (
        <ConfirmBidModal
          action={purchaseMethodShown}
          onConfirm={handleSubmitBid}
          onClose={() => {
            showPurchaseMethod(undefined);
            braze.logCustomEvent("Abandoned_purchase");
          }}
          bidDetails={{ price, quantity }}
          auction={auction}
          showTaxDisclaimer
        />
      ) : null}
    </CardCtx.Provider>
  );
}

export const useCardCtx = () => {
  const ctx = useContext(CardCtx);
  if (!ctx) {
    throw new Error("Card context provider not found");
  }
  return ctx;
};

export function CardCtxProvider(props: Props) {
  return (
    <ErrorBoundary fallback={<div>An unknown error has occurred...</div>}>
      <CardCtxProviderWithoutBoundary {...props} />
    </ErrorBoundary>
  );
}
