import styles from "./index.module.css";
import { PaymentMethods } from "./payment-methods";
import { ApiLotV1 } from "src/app/models/LotV1";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "src/app/hooks/useLoadStripe";
import { Button } from "src/app/components/button";
import { Tooltip } from "src/app/components/tooltip";
import { IoInformationCircleOutline } from "react-icons/io5";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { RowDesktop } from "src/app/components/table/_shared/row/row-desktop";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { DesktopCellContentName } from "src/app/components/table/_shared/cell-content-desktop/cell-content-name";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { ApiProduct } from "src/app/models/Product";
import { InvoiceAddress } from "./invoice-address";
import {
  useChooseDefaultCardMutation,
  useMyCardsQuery,
} from "src/app/api/payments";
import { Spacer } from "src/app/components/spacer";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useChangePaymentMethodMutation } from "src/app/api/mutations_new_to_rename";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { ID_PAYMENT_METHOD } from "src/app/const";
import { useState } from "react";
import { ExpressCheckoutButton } from "src/app/components/auction-card/lg/atoms.form/components/confirm-payment-modal/express-checkout-button";

type Props = {
  lots: ApiLotV1[] | undefined;
  products: FormProducts;
  totalToPay: number;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
};

const stripePromise = getStripe();

export type FormProducts = {
  [idProduct: string]: number;
};

type ApiProductWithQuantity = ApiProduct & { selectedQuantity: number };

export const StepCheckout = ({
  lots = [],
  products,
  totalToPay,
  onClose,
  onBack,
  onSubmit,
}: Props) => {
  const queryClient = useQueryClient();
  const user = useMyUser();
  const isMutating = !!useIsMutating();
  const [userPaymentMethod, setPaymentMethod] = useState(
    user.method_payment?.active.id_method_payment,
  );
  const { data: myCards = [] } = useMyCardsQuery();
  const priceInCents = totalToPay * 100;
  const changePaymentMethod = useChangePaymentMethodMutation({
    onSuccess: (paymentMethod) => setPaymentMethod(paymentMethod),
    onSettled: () => queryClient.invalidateQueries(),
  });
  const chooseDefaultCard = useChooseDefaultCardMutation({
    onSettled: () => queryClient.invalidateQueries(),
  });

  const selectedBottles = lots
    .flatMap((lot) => lot.components)
    .map((prod) => ({ ...prod, selectedQuantity: products[prod.id] }))
    .filter((prod) => !!prod.selectedQuantity);

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Transfer of bottles and payment summary:</h2>
      <div>
        <h3 className={styles.subtitle}>Payment Method</h3>
        <Spacer size={10} />
        <p className={styles.description}>
          Choose the payment method you want to use for this purchase
        </p>
      </div>
      <Elements
        options={{
          mode: "payment",
          amount: priceInCents,
          currency: "eur",
          loader: "always",
        }}
        stripe={stripePromise}
      >
        <>
          <PaymentMethods
            onClose={onClose}
            onChange={(paymentMethod, details) => {
              if (
                [ID_PAYMENT_METHOD.BANK, ID_PAYMENT_METHOD.CARD].includes(
                  paymentMethod,
                ) &&
                paymentMethod !== userPaymentMethod
              ) {
                changePaymentMethod.mutate({
                  id_method_payment: paymentMethod,
                });

                if (
                  paymentMethod === ID_PAYMENT_METHOD.CARD &&
                  details?.cardId
                ) {
                  chooseDefaultCard.mutate(details.cardId);
                }
              } else {
                setPaymentMethod(paymentMethod);
              }
            }}
          />

          {!!myCards.length && (
            <>
              <h3 className={styles.subtitle}>Invoice Address</h3>
              <div>
                <InvoiceAddress />
              </div>
            </>
          )}
          <table className={styles.summaryTable}>
            <thead>
              <tr>
                <th colSpan={3}>Summary</th>
                <th>Q.ty</th>
              </tr>
            </thead>
            <tbody>
              {selectedBottles.map((prod, index) => (
                <RowDesktop<ApiProductWithQuantity>
                  key={`row-deskt-${prod.id}-${index}`}
                  row={prod}
                  columns={[
                    {
                      header: "",
                      id: "vintage",
                      getSortingValue: (prod) => prod.vintage,
                      cellDesktop: (prod) => (
                        <DesktopCellContentText content={prod.vintage} />
                      ),
                      cellMobile: (prod) => (
                        <MobileCellContentText content={prod.vintage} />
                      ),
                    },
                    {
                      header: "",
                      id: "case",
                      getSortingValue: (prod) => prod.appellation,
                      cellDesktop: (prod) => (
                        <DesktopCellContentName
                          content={prod.appellation}
                          serif
                          minWidth={200}
                          maxLines={2}
                          details={
                            <p>
                              <span>{prod.color}</span>&nbsp;
                              <span>{prod.nameProductWithoutVintage}</span>
                              &nbsp;<span>{prod.id_country}</span>
                            </p>
                          }
                        />
                      ),
                    },
                    {
                      header: "",
                      id: "quantity",
                      getSortingValue: (prod) => prod.selectedQuantity,
                      cellDesktop: (prod) => {
                        return (
                          <p>
                            <span>{prod.selectedQuantity}</span>&nbsp;
                            <span>bottles</span>
                            <span>&nbsp;x&nbsp;</span>
                            <span>{prod.size}</span>L
                          </p>
                        );
                      },
                    },
                  ]}
                  enableSelectionForRow={undefined}
                  enableExpansionForRow={undefined}
                  minCellHeight={64}
                  renderSubRow={() => null}
                  getRowId={(prod) => prod.id}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={3}>Total Fees</th>
                <td className={styles.feeAmount}>
                  {formatCurrency(totalToPay)}
                </td>
              </tr>
            </tfoot>
          </table>
        </>
        <hr
          style={{
            marginTop: "auto",
          }}
        />
        <div className={styles.formButton}>
          <Button
            label="Back"
            onClick={onBack}
            variant="outlined"
            className={styles.backButton}
            disabled={isMutating}
          />
          <div style={{ marginLeft: "auto" }}>
            <Tooltip
              content={
                "Any remaining bottles will be split into individual lots of one bottle each. Until February 28th, all fees, including subdivision fees, are €0.00. After this date, a €10.00 fee will apply for each lot subdivision, payable before the transfer."
              }
            >
              <span>
                <IoInformationCircleOutline />
              </span>
            </Tooltip>
            Total fees: {formatCurrency(totalToPay)}
          </div>
          {userPaymentMethod === ID_PAYMENT_METHOD.EXPRESS_CHECKOUT ? (
            // <RenderExpressCheckoutButton />
            <span></span>
          ) : userPaymentMethod === ID_PAYMENT_METHOD.CARD ? (
            <Button
              label="Next"
              onClick={onSubmit}
              className={styles.submitButton}
              isLoading={isMutating}
            />
          ) : null}
        </div>
      </Elements>
    </div>
  );
};
