import { useStripe } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useSystemConfig } from "./useSystemConfig";
import { CanMakePaymentResult } from "@stripe/stripe-js";

export const useHasWalletPayment = () => {
  const userConfig = useSystemConfig();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(true);
  const [wallets, setWallets] = useState<CanMakePaymentResult | null>(null);

  const checkStripe = useCallback(async () => {
    if (stripe && userConfig) {
      const outcome = await stripe
        .paymentRequest({
          country: userConfig.legal_address.address.country.countryISO,
          currency: "eur",
          total: {
            label: "",
            amount: 0,
          },
        })
        .canMakePayment();

      console.log(outcome);
      setWallets(outcome);
      setIsLoading(false);
    }
  }, [userConfig, stripe]);

  useEffect(() => {
    checkStripe();
  }, [checkStripe]);

  return { wallets, isLoading };
};
