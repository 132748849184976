import styles from "./step-cases.module.css";
import { ApiLotV1 } from "src/app/models/LotV1";
import { Spacer } from "src/app/components/spacer";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { Button } from "src/app/components/button";
import { exportForm } from ".";
import { ProductSubrow, QuantityOption } from "./product-subrow";
import { Tooltip } from "src/app/components/tooltip";
import { IoInformationCircleOutline } from "react-icons/io5";
import { CRUTRADE_EXPORT_FEE_EURO } from "src/app/const";
import { useIsMutating } from "@tanstack/react-query";
import { CellContentBottleCount } from "../../lots/cell-content-bottle-count";
import { DesktopCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-desktop/cell-content-total-price";
import { MobileCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-mobile/cell-content-total-price";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { getLotName } from "../../lots/get-lot-name";

type Props = {
  lots: ApiLotV1[] | undefined;
  onClose: () => void;
  onNext: () => void;
};

export const StepPickCases = ({ lots, onClose, onNext }: Props) => {
  const ctx = exportForm.useFormCtx();
  const prodQuantities = ctx.getFieldValue("products");
  const totalFees = getTotalFees(lots || [], prodQuantities);
  const isMutating = !!useIsMutating();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <h2>Choose bottles from cases to send and trade on CruTrade</h2>
      <Spacer size={20} />
      <p>
        Choose if you want to send a portion of a case by specifying the number
        of bottles.
      </p>
      <Spacer size={20} />
      <exportForm.Form>
        <TableCustomerSide
          urlPrefix="e."
          data={lots}
          getRowId={(row) => row.id_lot}
          enableExpansionForRow={() => true}
          defaultExpanded={true}
          isFetching={false}
          isLoading={false}
          enablePagination={false}
          fillMissingRows={false}
          minCellHeightDesktop="78px"
          noDataTitle="No lot selected"
          noDataContent={
            <div>
              <p>Select at least one case to start exporting</p>
              <Button label="Select cases" onClick={onClose} />
            </div>
          }
          renderSubRow={ProductSubrow}
          columns={[
            {
              header: "Lot name",
              id: "case",
              getSortingValue: getLotName,
              cellDesktop: (lot) => (
                <DesktopCellContentText
                  content={getLotName(lot)}
                  serif
                  minWidth={200}
                  maxLines={2}
                />
              ),
              cellMobile: (lot) => (
                <MobileCellContentText content={getLotName(lot)} serif />
              ),
            },
            {
              header: "Total Btl.",
              id: "quantity",
              width: "200px",
              getSortingValue: (lot) => lot.serials.length,
              cellDesktop: (lot) => {
                return <CellContentBottleCount lot={lot} />;
              },
            },
            {
              header: "Total",
              id: "total",
              width: "150px",
              getSortingValue: (lot) =>
                lot.finance.total + lot.finance.total_insurance,
              cellDesktop: (lot) => (
                <DesktopCellContentTotalPrice
                  align="left"
                  details={[
                    {
                      amount: lot.finance.pmc,
                      label: "price",
                    },
                    {
                      amount: lot.finance.insurance,
                      label: "processing fee",
                    },
                    { label: "Excl. of processing fees and taxes" },
                  ]}
                />
              ),
              cellMobile: (lot) => (
                <MobileCellContentTotalPrice
                  details={[
                    {
                      amount: lot.finance.pmc,
                      label: "price",
                    },
                    {
                      amount: lot.finance.insurance,
                      label: "processing fee",
                    },
                    {
                      label: (
                        <span style={{ fontStyle: "italic" }}>
                          Excl. of processing fees and taxes
                        </span>
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </exportForm.Form>
      <div style={{ marginTop: "auto" }}>
        {!!ctx.hasErrors && ctx.getFieldError("products") && (
          <div className="p-2 text-center text-red mt-auto">
            {ctx.getFieldError("products")}
          </div>
        )}
        <hr />
        <div className={styles.footer}>
          <Button
            label="Close"
            variant="outlined"
            onClick={onClose}
            className={styles.button}
            disabled={isMutating}
          />
          <div className={styles.totalFees}>
            <Tooltip
              content={
                "Any remaining bottles will be split into individual lots of one bottle each. Until February 28th, all fees, including subdivision fees, are €0.00. After this date, a €10.00 fee will apply for each lot subdivision, payable before the transfer."
              }
            >
              <span>
                <IoInformationCircleOutline />
              </span>
            </Tooltip>
            <span>Total fees:</span>&nbsp;
            {formatCurrency(totalFees)}
          </div>
          <Button
            label="Confirm"
            onClick={onNext}
            className={styles.button}
            isLoading={isMutating}
          />
        </div>
      </div>
    </div>
  );
};



export function getTotalLotQuantities(
  lot: ApiLotV1,
  prodQuantities: Record<number, QuantityOption | undefined>,
) {
  // Total cases across all lot bottles
  const totalCases = lot.components
    .map((prod) =>
      Math.ceil((prodQuantities[prod.id_serial]?.value || 0) / prod.quantity),
    )
    .reduce((maxCases, prodCases) => Math.max(maxCases, prodCases), 0);

  const totBottles = lot.components.reduce(
    (tot, prod) => tot + (prodQuantities[prod.id_serial]?.value || 0),
    0,
  );

  const size = lot.components.reduce(
    (size, prod) =>
      !!size && size !== prod.size.toString() ? "mixed" : prod.size.toString(),
    "",
  );

  return {
    totBottles,
    totalCases,
    size,
  };
}

export function getTotalFees(
  lots: ApiLotV1[],
  prodQuantities: Record<number, QuantityOption | undefined>,
) {
  return lots.reduce((total, lot) => {
    return (
      total +
      getTotalLotQuantities(lot, prodQuantities).totalCases *
        CRUTRADE_EXPORT_FEE_EURO
    );
  }, 0);
}
