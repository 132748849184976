import styles from "../../index.module.css";
import { Fragment, useId } from "react";
import { noop } from "lodash";
import { RowProps, TDataBase } from "../../types";
import { noRender } from "../../const";
import { Checkbox } from "src/app/components/form-elements/checkbox";
import { ArrowExpand } from "../arrow-expand";
import { toPx } from "src/app/utils/toPx";

export function RowMobile<TData extends TDataBase>({
  row,
  columns,
  enableSelectionForRow,
  enableExpansionForRow,
  getRowId,
  minCellHeight,
  onClickSelect = noop,
  onClickExpand = noop,
  isExpanded = false,
  isSelected = false,
  isFetching = false,
  renderSubRow: RenderSubRow = noRender,
}: RowProps<TData>) {
  const id = useId();
  return (
    <Fragment>
      <tr>
        <td className={styles.tableCell}>
          <dl className={styles.rowMobile}>
            {!!enableSelectionForRow && (
              <Fragment>
                <div>
                  <Checkbox
                    className={styles.checkbox}
                    checked={isSelected}
                    disabled={!enableSelectionForRow(row) || isFetching}
                    onChange={() => onClickSelect(row)}
                  />
                </div>
                <div />
              </Fragment>
            )}

            {columns.map((col, i) => (
              <Fragment key={`mob-row-${id}-${getRowId(row)}-${i}`}>
                <dt
                  className={styles.cellHeaderMobile}
                  style={{ minHeight: toPx(minCellHeight) }}
                >
                  {col.header}
                </dt>
                <dd
                  style={{ minHeight: toPx(minCellHeight), gridColumnStart: 2 }}
                >
                  {(col.cellMobile || col.cellDesktop)(row)}
                </dd>
              </Fragment>
            ))}

            {!!enableExpansionForRow && (
              <div style={{ gridColumn: "1 / -1" }}>
                {enableExpansionForRow(row) && (
                  <ArrowExpand
                    disabled={isFetching}
                    onClick={() => onClickExpand(row)}
                    isExpanded={isExpanded}
                  />
                )}
              </div>
            )}
          </dl>
        </td>
      </tr>

      {!!isExpanded && <RenderSubRow row={row} isMobile={true} />}
    </Fragment>
  );
}
