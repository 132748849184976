import { ReactNode } from "react";
import { env } from "src/env";
import { FlagSchema, flags } from "./schema";
import { DevTooltip } from "./dev-tooltip";

type Props = {
  hasPermission: boolean;
  children: (flags: FlagSchema) => ReactNode;
  fallback?: ReactNode;
  showDevTooltip?: boolean;
};

export const FeatureFlag = ({
  hasPermission,
  children,
  fallback,
  showDevTooltip = true,
}: Props) => {

  return (
    <>
      {hasPermission ? children(flags) : !!fallback && fallback}
      {!!env.isDev && !!showDevTooltip && <DevTooltip displayed={hasPermission} />}
    </>
  );
};
