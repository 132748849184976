import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { upfetchV3 } from "./upfetch";
import { QKEY } from "./QKEY";
import { z } from "zod";
import { apiCrutradeJobSchema, ApiCrutradeJob } from "../models/crutrade";
import { ApiProduct, apiProductSchema } from "../models/Product";

export const useOtpVerificationMutation = (
  options: UseMutationOptions<
    any,
    Error,
    {
      code: string;
    }
  > = {},
) => {
  return useMutation<unknown, Error, { code: string }>({
    ...options,
    mutationFn: (payload) => {
      return upfetchV3({
        url: "connection/crutrade/link",
        method: "POST",
        body: payload,
      });
    },
  });
};

const getCrutradeCollections = (id_user: number) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.CRUTRADE_COLLECTIONS, id_user],
  queryFn: async () => {
    const data = await upfetchV3({
      url: "connection/crutrade/collections",
      params: { id_user },
      schema: z.object({
        data: z.object({
          collection: z.array(apiProductSchema),
          meta: z.any(),
        }),
      }),
    });

    return data.data;
  },
});

export const useCrutradeCollectionsQuery = (
  id_user: number,
  options: UseQueryOptions<{
    collection: Array<ApiProduct>;
    meta?: any;
  }> = {},
) => {
  return useQuery({
    queryKey: getCrutradeCollections(id_user).queryKey,
    queryFn: getCrutradeCollections(id_user).queryFn,
    ...options,
  });
};

const getCrutradeLink = (id_user: number) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.CRUTRADE_LINK, id_user],
  queryFn: async () => {
    const data = await upfetchV3({
      url: "connection/crutrade/link",
      params: { id_user },
      schema: z.object({
        data: z.object({
          wallet_address: z.string(),
        }),
      }),
    });

    return data.data;
  },
});

export const useCrutradeLinkQuery = (
  id_user: number,
  options: UseQueryOptions<{ wallet_address: string }> = {},
) => {
  return useQuery({
    queryKey: getCrutradeLink(id_user).queryKey,
    queryFn: getCrutradeLink(id_user).queryFn,
    ...options,
  });
};

type ExportPayload = {
  products: Array<ApiProduct>;
};

export const useExportToCrutradeMutation = (
  options: UseMutationOptions<any, any, ExportPayload>,
) => {
  return useMutation({
    ...options,
    mutationFn: async ({ products }) => {
      const payload = products.map((el) => el.id_serial);
      return await upfetchV3({
        url: `connection/crutrade/collections/export`,
        method: "POST",
        body: {
          serial_ids: payload,
        },
        parseResponse: async (res) => await res.json(),
      });
    },
  });
};

type ImportPayload = {
  products: Array<ApiProduct>;
};

export const useImportFromCrutradeMutation = (
  options: UseMutationOptions<any, any, ImportPayload>,
) => {
  return useMutation({
    ...options,
    mutationFn: async ({ products }) => {
      const payload = products.map((el) => ({
        wrapperId: el.wrapper_id,
        serialId: el.id_serial,
      }));
      return await upfetchV3({
        url: `connection/crutrade/collections/import`,
        method: "POST",
        body: { collections: payload },
      });
    },
  });
};

const getJobStatus = () => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.CRUTRADE_JOBS],
  queryFn: async () => {
    const data = await upfetchV3({
      // url: "notifications/jobs/unread",
      url: "notifications/jobs/unread",
      schema: z.array(apiCrutradeJobSchema),
    });
    return data.map((job) => ({
      ...job,
      updated_at: new Date(job.updated_at),
    }));
  },
});

export const useCrutradeJobStatusQuery = (
  options: UseQueryOptions<Array<ApiCrutradeJob>> = {},
) => {
  return useQuery({
    queryKey: getJobStatus().queryKey,
    queryFn: getJobStatus().queryFn,
    ...options,
  });
};

export const useCrutradeJobReadMutation = (
  options: UseMutationOptions<any, any, { jobs: Array<ApiCrutradeJob> }> = {},
) => {
  return useMutation({
    ...options,
    mutationFn: ({ jobs }) =>
      upfetchV3({
        url: "notifications/batch/read",
        method: "PATCH",
        body: { ids: jobs.map((job) => job.id) },
      }),
  });
};

// export const useCrutradeJobStatusMutation = (options = {}) => {
//   return useMutation({
//     ...options,
//     mutationFn: () =>
//       upfetchV3({
//         url: "connection/crutrade/jobs/last-statuses",
//         method: "GET",
//         schema: z.object({
//           data: z.array(apiCrutradeJobSchema),
//         }),
//       }),
//   });
// };
