import bottleRedSrc from "src/media/wine-red.svg";
import bottleRoseSrc from "src/media/wine-rose.svg";
import bottleWhiteSrc from "src/media/wine-white.svg";
import bottleBrutSrc from "src/media/wine-brut-nature-blanc-de-blancs.svg";
import { ApiProduct } from "src/app/models/Product";

type Props = {
  product: ApiProduct;
  className?: string;
};

export function CellContentBottleSize({ product, className = "" }: Props) {
  const productColor = product.color.toLowerCase();

  return (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      <img
        src={
          productColor === "red"
            ? bottleRedSrc
            : productColor === "rose"
              ? bottleRoseSrc
              : productColor === "white"
                ? bottleWhiteSrc
                : bottleBrutSrc
        }
        alt={productColor}
      />
      <div style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
        <span>{product.size}</span>
        <span>L</span>
      </div>
    </div>
  );
}
