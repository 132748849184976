import styles from "./index.module.css";
import { Button } from "src/app/components/button";
import { FaArrowTrendUp } from "react-icons/fa6";
import { defineDialog } from "crustack/dialog";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { Spacer } from "src/app/components/spacer";
import { ReactComponent as CrutradeLogoLight } from "src/media/logos/crutrade.svg";
import { InputOtpDialogStep } from "./step-otp";
import { useCrutradeLinkQuery } from "src/app/api/crutrade";
import { useMyUser } from "src/app/hooks/useMyUser";
import { StepWalkthrough } from "../__common/step-walkthrough";
import { Skeleton } from "src/app/components/skeleton";
import { useQueryClient } from "@tanstack/react-query";
import { QKEY } from "src/app/api/QKEY";
import { can } from "src/feature-flag/schema";

type Step = "input-otp" | "success" | "walkthrough";

const dialog = defineDialog();

export const DialogCrutradeConnect = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const user = useMyUser();
  const { data: crutradeConnection, isLoading: isLoadingConnection } =
    useCrutradeLinkQuery(user.id_user);
  const [currentStep, setStep] = useState<Step>(
    crutradeConnection ? "walkthrough" : "input-otp",
  );

  useEffect(() => {
    if (crutradeConnection) setStep("walkthrough");
  }, [crutradeConnection]);

  const canAccessCrutrade = can.accessCrutrade(user);

  if (!canAccessCrutrade) return null;

  return (
    <div>
      {isLoadingConnection ? (
        <Skeleton.Rect width={164} height="100%" />
      ) : crutradeConnection ? (
        <div className={styles.successBadge}>Crutrade Connected</div>
      ) : (
        <Button
          label="Connect Crutrade"
          icon={<FaArrowTrendUp />}
          onClick={() => setIsOpen(true)}
        />
      )}
      <dialog.Root
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={styles.root}
      >
        <div className={styles.dialogHeader}>
          <Button
            variant="ghost"
            label=""
            icon={
              currentStep !== "walkthrough" ? (
                <MdClose />
              ) : (
                <small className={styles.closeHandle}>Skip</small>
              )
            }
            onClick={() => setIsOpen(false)}
          />
        </div>
        <Spacer size={32} />

        {currentStep !== "walkthrough" && (
          <div style={{ textAlign: "center" }}>
            <CrutradeLogoLight />
          </div>
        )}
        {currentStep === "walkthrough" ? (
          <StepWalkthrough onClose={() => setIsOpen(false)} />
        ) : currentStep === "success" ? (
          <div
            className={styles.body}
            style={{ textAlign: "center", alignItems: "center" }}
          >
            <Spacer size={32} />
            <div className={styles.successBadge}>Your cellar is connected</div>
            <Spacer size={28} />
            <p className={styles.subtitle}>
              Start to import or export your NFTs,
              <span style={{ display: "block" }} /> you can do it directly from
              Crurated.
            </p>
            <Spacer size={12} />
            <p className={styles.subtitle}>
              Let&apos;s follow the instruction and start your trading!
            </p>
            <Spacer size={32} />
            <Button
              label="Get Started"
              onClick={() => setStep("walkthrough")}
              className={styles.ctaButton}
            />
          </div>
        ) : (
          <InputOtpDialogStep
            onSuccess={() => {
              queryClient.invalidateQueries({
                queryKey: [QKEY.MY_ASSETS, QKEY.CRUTRADE_LINK, user.id_user],
              });
              setStep("success");
            }}
          />
        )}
      </dialog.Root>
    </div>
  );
};
