import { ApiLotV1 } from "src/app/models/LotV1";
import { ApiProduct } from "src/app/models/Product";
import { QuantityOption } from "./product-subrow";
import { mapValues } from "lodash";

export const getProdId = (prod: ApiProduct) => `${prod.id_product}-${prod.price}`;

export const getGroupedLotsProducts = (...lots: ApiLotV1[]) => {
  return (
    lots
      // Flat list of lot components
      .flatMap((lot) => lot.components)
      // Group components by their product type and price and update available quantity
      .reduce((prodByIdAndPrice, product) => {
        // Check if a product type with the same price already exists
        const existingProduct = prodByIdAndPrice.find(
          (el) =>
            getProdId(el) === getProdId(product) && el.price === product.price,
        );

        if (!existingProduct) {
          // Create a new ref, avoid modifying existing products
          prodByIdAndPrice.push({ ...product, quantity: 1 });
        } else {
          existingProduct.quantity++;
        }

        return prodByIdAndPrice;
      }, new Array<ApiProduct>())
  );
};

export const mapProductQuantitiesToActualProducts = (
  availableLots: ApiLotV1[],
  selectedProductsWithQuantity: {
    [idProduct: string]: QuantityOption | undefined;
  },
): Array<ApiProduct> => {
  const quantityRef = mapValues(
    selectedProductsWithQuantity,
    (option) => option?.value || 0,
  );

  return availableLots
    .flatMap((lot) => lot.components)
    .reduce((selectedProducts, product) => {
      const productId = getProdId(product);
      if (productId in quantityRef && quantityRef[productId] > 0) {
        selectedProducts.push(product);
        quantityRef[productId] -= 1;
      }
      return selectedProducts;
    }, new Array<ApiProduct>());
};

export const parseQuantityLabels = (
  prod: ApiProduct,
): [QuantityOption, ...QuantityOption[]] => {
  const availableQuantities = Array.from({ length: prod.quantity }, (_, i) =>
    getOptionForQuantity(i + 1, prod.size),
  );

  return [
    {
      value: 0,
      label: `0 bottles x ${prod.size}L`,
      description: `0 bottles x ${prod.size}L`,
    },
    ...availableQuantities,
  ];
};

export const getOptionForQuantity = (
  quantity: number,
  size: number,
): QuantityOption => {
  return {
    value: quantity,
    label: `${quantity} bottles x ${size}L`,
    description: `${quantity} bottles x ${size}L`,
  };
};
