import { ErrorBaseUI } from "./error-base-ui";

export const PageErrorMaintenance = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <div style={{ maxWidth: "34rem" }}>
        <ErrorBaseUI
          title="Maintenance"
          text="The Crurated platform is currently undergoing scheduled maintenance.
We aim to have the website back up-and-running by shortly.
We apologise for any inconvenience this may cause and appreciate your patience as we strive to provide you with a better website and improved experience."
        />
      </div>
    </div>
  );
};
