import styles from "../styles.module.css";
import { Fragment, useState } from "react";
import { minutes } from "src/app/utils/time";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { BulkActions } from "../bulk-actions";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { DesktopCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-desktop/cell-content-total-price";
import { MobileCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-mobile/cell-content-total-price";
import { DesktopCellContentEmp } from "src/app/components/table/_shared/cell-content-desktop/cell-content-emp";
import { MobileCellContentEmp } from "src/app/components/table/_shared/cell-content-mobile/cell-content-emp";
import { DesktopCellContentLiters } from "src/app/components/table/_shared/cell-content-desktop/cell-content-liters";
import { MobileCellContentLiters } from "src/app/components/table/_shared/cell-content-mobile/cell-content-liters";
import { TableProductStatus } from "../../../modules/Includes/CustomerSide/components/cellar/TableProductStatus";
import { BarrelConvertPopupDynamic } from "src/app/modules/Includes/LayoutSide/components/barrels/BarrelConvertPopupDynamic";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import { path } from "src/app/routes/path";
import { Button } from "src/app/components/button";
import { useMyBarrelsQuery } from "src/app/api/queries_to_rename_later";
import { ApiBarrelV1 } from "src/app/models/BarrelV1";
import { useMyUser } from "src/app/hooks/useMyUser";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { inferDataType } from "src/app/utils/types";
import { useInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useInputAutocomplete";
import { noop } from "lodash";
import { A } from "src/app/components/a";
import { useDownload } from "src/app/hooks/useDownload";

const getRowId = (row: ApiBarrelV1) => row.id_barrel;

export const PageBarrels = () => {
  const isMobile = useIsMobile();
  const user = useMyUser();
  const download = useDownload();

  const [barrelToConvert, setBarrelToConvert] = useState<ApiBarrelV1 | null>(
    null,
  );

  const barrelsQuery = useMyBarrelsQuery(user.id_user, {
    staleTime: minutes(1),
  });

  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<ApiBarrelV1>(),
    config: [
      {
        groupName: "Status",
        getValue: () => "in_barrel",
        getLabel: () => "In Barrel",
      },
      {
        groupName: "Region",
        getValue: (barrel) => barrel.product_region,
      },
      {
        groupName: "Color",
        getValue: (barrel) => barrel.product_color,
      },
      {
        groupName: "Winery",
        getValue: (barrel) => barrel.auction.barrels.winery_name,
      },
      {
        groupName: "Vintage",
        getValue: (barrel) =>
          +barrel.product_vintage === 0
            ? " No Vintage"
            : barrel.product_vintage,
      },
    ],
  });

  const inputAutocomplete = useInputAutocomplete({
    inferDataType: inferDataType<ApiBarrelV1>(),
    getSuggestionText: (barrel) => barrel.description_barrel_auction,
  });

  const filteredBarrels = (barrelsQuery.data || [])
    .filter(selectMultiple.filterFn)
    .filter(inputAutocomplete.filterFn);

  return (
    <Fragment>
      {!!barrelToConvert && (
        <BarrelConvertPopupDynamic
          key={`convert-${barrelToConvert.id_barrel_auction_participant}`}
          dataParticipant={barrelToConvert}
          participant={{
            liters_available: barrelToConvert.liters_available,
            maxCapacity: +barrelToConvert.liters_available,
          }}
          colorWine={barrelToConvert.color}
          user={user}
          idUserProfile={user.id_user}
          closeHandler={() => setBarrelToConvert(null)}
        />
      )}

      <div className={`${styles.controlBar} ${isMobile ? styles.mobile : ""}`}>
        <div className={styles.filters}>
          <SelectMultipleFromData
            placeholder="Filters"
            data={filteredBarrels}
            values={selectMultiple.values}
            onChange={selectMultiple.setValues}
            config={selectMultiple.config}
          />
        </div>

        <InputAutocomplete
          data={filteredBarrels}
          getSuggestionText={inputAutocomplete.getSuggestionText}
          onChange={inputAutocomplete.setValue}
          value={inputAutocomplete.value}
        />

        <BulkActions
          selection={[]}
          onClearSelection={noop}
          style={{ width: "100%" }}
          actions={
            [
              // {
              //   label: "Download Barrels Report",
              //   onClick: () => download.barrelsCSV(barrelsQuery.data),
              //   disabled: !barrelsQuery.data?.length,
              // },
            ]
          }
        />
      </div>

      <TableCustomerSide
        urlPrefix=""
        data={filteredBarrels}
        isLoading={barrelsQuery.isLoading}
        isFetching={barrelsQuery.isFetching}
        minCellHeightDesktop={100}
        noDataTitle="Your Cellar is empty"
        getRowId={getRowId}
        noDataContent={
          <div>
            <p>View live auctions to make a new bid.</p>
            <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
          </div>
        }
        columns={[
          {
            id: "Products",
            header: "Products",
            getSortingValue: (barrel) => barrel.description_barrel_auction,
            cellDesktop: (barrel) => (
              <DesktopCellContentText
                content={barrel.description_barrel_auction}
                serif
                minWidth="200px"
                maxLines={2}
              />
            ),
            cellMobile: (barrel) => (
              <MobileCellContentText
                content={barrel.description_barrel_auction}
                serif
              />
            ),
          },
          {
            header: "EMP",
            id: "emp",
            getSortingValue: (barrel) =>
              barrel.crurated_estimated_market_price * barrel.quantity_winner,
            alignHeaderDesktop: "center",
            cellDesktop: (barrel) => (
              <DesktopCellContentEmp
                emp={
                  barrel.crurated_estimated_market_price *
                  barrel.quantity_winner
                }
                ratio={
                  ((barrel.crurated_estimated_market_price *
                    barrel.quantity_winner -
                    barrel.total_bid) /
                    barrel.total_bid) *
                  100
                }
              />
            ),
            cellMobile: (barrel) => (
              <MobileCellContentEmp
                emp={
                  barrel.crurated_estimated_market_price *
                  barrel.quantity_winner
                }
                ratio={
                  ((barrel.crurated_estimated_market_price *
                    barrel.quantity_winner -
                    barrel.total_bid) /
                    barrel.total_bid) *
                  100
                }
              />
            ),
          },
          {
            header: "Total",
            id: "total",
            getSortingValue: (barrel) => barrel.bid_winner + barrel.insurance,
            alignHeaderDesktop: "center",
            cellDesktop: (barrel) => (
              <DesktopCellContentTotalPrice
                paid={barrel.paid}
                details={[
                  { amount: barrel.bid_winner, label: "price" },
                  { amount: barrel.insurance, label: "processing fee" },
                ]}
              />
            ),
            cellMobile: (barrel) => (
              <MobileCellContentTotalPrice
                paid={barrel.paid}
                details={[
                  { amount: barrel.bid_winner, label: "price" },
                  { amount: barrel.insurance, label: "processing fee" },
                ]}
              />
            ),
          },
          {
            header: "Qty.",
            id: "Qty.",
            alignHeaderDesktop: "right",
            getSortingValue: (barrel) =>
              barrel.crurated_estimated_market_price * barrel.quantity_winner,
            cellDesktop: (barrel) => (
              <DesktopCellContentLiters liters={barrel.liters_available} />
            ),
            cellMobile: (barrel) => (
              <MobileCellContentLiters liters={barrel.liters_available} />
            ),
          },
          {
            header: "Status",
            id: "Status",
            // TODO: remove TableProductStatus
            cellDesktop: (barrel) => (
              <div>
                <TableProductStatus
                  status={"in_barrel"}
                  available_date={barrel.available_date}
                />
              </div>
            ),
          },
          {
            header: "Actions",
            id: "Actions",
            cellDesktop: (barrel) => (
              <div>
                <button
                  style={{
                    fontSize: "12px",
                    fontFamily: "Inter",
                    background: "var(--color-primary)",
                    padding: "6px 14px",
                    color: "white",
                    borderRadius: "2px",
                    fontWeight: 600,
                  }}
                  onClick={() => setBarrelToConvert(barrel)}
                  disabled={
                    // this is copy/paste from old code
                    ((!!user.allow_executive &&
                      +barrel.auction.barrels.time_left_days === 0) ||
                      barrel.auction.barrels.time_left_days > 0) &&
                    barrel.liters_available > 0
                      ? false
                      : true
                  }
                >
                  Convert
                </button>
                <div
                  style={{
                    fontSize: "10px",
                    color: "#5f302f",
                    marginTop: "6px",
                  }}
                >
                  {+barrel.auction.barrels.time_left_days > 0
                    ? barrel.liters_available > 0
                      ? `${barrel.auction.barrels.time_left_days} days left to convert`
                      : `All barrel was converted`
                    : `The days to convert are over`}
                </div>
              </div>
            ),
          },
        ]}
      />
    </Fragment>
  );
};
