import styles from "../../index.module.css";
import { Fragment } from "react";
import { noop } from "lodash";
import { RowProps, TDataBase } from "../../types";
import { noRender } from "../../const";
import { Checkbox } from "src/app/components/form-elements/checkbox";
import { ArrowExpand } from "../arrow-expand";
import { toPx } from "src/app/utils/toPx";
import { cn } from "src/app/utils/cn";

export function RowDesktop<TData extends TDataBase>({
  row,
  columns,
  enableSelectionForRow,
  enableExpansionForRow,
  getRowId,
  minCellHeight,
  onClickSelect = noop,
  onClickExpand = noop,
  isSelected = false,
  isFetching = false,
  isExpanded = false,
  renderSubRow: RenderSubRow = noRender,
}: RowProps<TData>) {
  return (
    <Fragment>
      <tr
        tabIndex={-1}
        key={`${row.status}-op-row-${getRowId(row)}`}
        className={styles.tableRow}
      >
        <td style={{ height: toPx(minCellHeight), width: 0 }} />

        {!!enableExpansionForRow && (
          <td className={cn(styles.tableCell, styles.expansionCell)}>
            {enableExpansionForRow(row) && (
              <ArrowExpand
                disabled={isFetching}
                onClick={() => onClickExpand(row)}
                isExpanded={isExpanded}
              />
            )}
          </td>
        )}

        {!!enableSelectionForRow && (
          <td className={cn(styles.tableCell, styles.selectionCell)}>
            <Checkbox
              className={styles.checkbox}
              checked={isSelected}
              disabled={!enableSelectionForRow(row) || isFetching}
              onChange={() => onClickSelect(row)}
            />
          </td>
        )}

        {columns.map((col, i) => (
          <td
            className={styles.tableCell}
            key={`desk-row-${getRowId(row)}-${i}`}
            style={{ width: col.width || "auto" }}
          >
            {col.cellDesktop(row)}
          </td>
        ))}
      </tr>

      {!!isExpanded && <RenderSubRow row={row} isMobile={false} />}
    </Fragment>
  );
}
