import styles from "./index.module.css";
import { useAlertPopup } from "./store";
import { cn } from "src/app/utils/cn";
import { PiCheckCircleDuotone, PiWarningCircleDuotone } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { useIsMutating } from "@tanstack/react-query";
import { CSSProperties, ReactNode } from "react";
import { Button, ButtonProps } from "../button";
import { A } from "../a";
import { useDebounceValue } from "src/app/hooks/useDebounceValue";
import { HtmlDialog } from "../html-dialog";

export type AlertProps = {
  title: ReactNode;
  content?: ReactNode;
  onClose?: () => void;
  closeButton?: {
    label: string;
  };
  iconStyles?: CSSProperties;
};

export type WithActionButton<Props> = Props & {
  actionButton?: {
    label: string;
    onClick?: (onClose: () => void) => void;
    linkTo?: ButtonProps<typeof A>["to"];
  };
};

export const AlertPopupError = (props: AlertProps) => (
  <Dialog
    {...props}
    icon={
      <PiWarningCircleDuotone
        className={cn(styles.icon, styles.warning)}
        style={props.iconStyles}
      />
    }
  />
);

export const AlertPopupSuccess = (props: WithActionButton<AlertProps>) => (
  <Dialog
    {...props}
    icon={
      <PiCheckCircleDuotone
        className={cn(styles.icon, styles.success)}
        style={props.iconStyles}
      />
    }
  />
);

export const AlertPopupPrompt = (props: WithActionButton<AlertProps>) => (
  <Dialog {...props} />
);

function Dialog({
  title,
  content,
  onClose,
  actionButton,
  closeButton,
  icon,
}: WithActionButton<AlertProps> & { icon?: ReactNode }) {
  const alertPopup = useAlertPopup();
  const isMutating = !!useIsMutating();
  const isMutatingDebounced = useDebounceValue(isMutating, 300);
  // delay has to match the closing animation duration
  const debouncedIsOpen = useDebounceValue(alertPopup.currentPopup.isOpen, 150);
  const isClosing = !alertPopup.currentPopup.isOpen && debouncedIsOpen;

  const isOpen = alertPopup.currentPopup.isOpen || debouncedIsOpen;

  const handleClose = () => {
    if (!isOpen) return;
    alertPopup.close();
    onClose?.();
  };

  return (
    <HtmlDialog
      mandatory={false}
      isOpen={isOpen}
      onClose={handleClose}
      className={cn(styles.root, isClosing && styles.closing)}
    >
      <button
        className={styles.closeButton}
        onClick={handleClose}
        aria-label="Close"
        disabled={isMutating}
      >
        <AiOutlineClose />
      </button>

      <div className={styles.content}>
        {icon}
        <h2>{title}</h2>
        <div>{content}</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            label={
              actionButton
                ? closeButton?.label || "Cancel"
                : closeButton?.label || "OK"
            }
            onClick={handleClose}
            disabled={isMutating}
            variant={actionButton ? "outlined" : "contained"}
            isLoading={isMutatingDebounced}
          />
          {!!actionButton && (
            <Button
              as={actionButton.linkTo ? A : "button"}
              className={styles.button}
              label={actionButton.label}
              disabled={isMutating}
              onClick={() => {
                actionButton.onClick?.(handleClose);
                if (actionButton.linkTo) handleClose();
              }}
              to={actionButton.linkTo}
              isLoading={isMutatingDebounced}
            />
          )}
        </div>
      </div>
    </HtmlDialog>
  );
}
