import { range } from "lodash";
import { UserV1 } from "src/app/models/UserV1";
import { env } from "src/env";

export type FlagSchema = {
  // expressCheckoutUserIds: number[],
  canUseCrutrade?: number[];
};

const development: FlagSchema = {
  canUseCrutrade: range(1, 15000),
};

const production: FlagSchema = {
  canUseCrutrade: [],
};

const isProd = env.REACT_APP_VERCEL_ENV === "production";

export const flags = isProd ? production : development;

export const can = {
  accessCrutrade: (user: UserV1) =>
    !!flags.canUseCrutrade?.includes(user.id_user),
};
