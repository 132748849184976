import styles from "./index.module.css";
import { Spacer } from "src/app/components/spacer";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { DesktopCellContentText } from "src/app/components/table/_shared//cell-content-desktop/cell-content-text";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { Button } from "src/app/components/button";
import { importForm } from ".";
import { useIsMutating } from "@tanstack/react-query";
import { ApiProduct } from "src/app/models/Product";
import { CellContentBottleSize } from "../../lots/cell-content-bottle-size";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";

type Props = {
  products: ApiProduct[] | undefined;
  onClose: () => void;
  onNext: () => void;
};

export const StepBottles = ({ products, onNext, onClose }: Props) => {
  const ctx = importForm.useFormCtx();
  const isMutating = !!useIsMutating();

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <h2>Choose bottles to receive from Crutrade</h2>
      <Spacer size={20} />
      <p>Choose which bootles you want to import from Crutrade.</p>
      <Spacer size={20} />
      <importForm.Form>
        <importForm.Field
          name={`products`}
          validate={(products) => {
            if (products.length === 0) return "Select at least one lot";
          }}
        >
          <TableCustomerSide
            urlPrefix="i."
            data={products}
            getRowId={(row) => row.id_serial}
            defaultExpanded={false}
            enableSelectionForRow={() => true}
            enablePagination={false}
            selection={ctx.getFieldValue("products")}
            onChangeSelection={(rows) => ctx.setFieldValue("products", rows)}
            isFetching={false}
            isLoading={false}
            fillMissingRows={false}
            minCellHeightDesktop={64}
            noDataTitle="No collection available"
            noDataContent={
              <div>
                <p>Start trading on Crutrade to enable importing</p>
                <Button label="Close" onClick={onClose} />
              </div>
            }
            columns={[
              {
                header: "Products",
                id: "product",
                getSortingValue: (prod) => prod.nameProductWithoutVintage,
                cellDesktop: (prod) => (
                  <DesktopCellContentText
                    content={`${prod.vintage}, ${prod.nameProductWithoutVintage}`}
                    serif
                    minWidth={200}
                    maxLines={2}
                  />
                ),
                cellMobile: (prod) => (
                  <MobileCellContentText
                    content={`${prod.vintage}, ${prod.nameProductWithoutVintage}`}
                    serif
                  />
                ),
              },
              {
                header: "Btl. Size",
                id: "bottle_size",
                width: "150px",
                getSortingValue: (prod) => prod.size,
                cellDesktop: (prod) => <CellContentBottleSize product={prod} />,
              },
              {
                header: "Price",
                id: "price",
                width: "150px",
                getSortingValue: (prod) => prod.price,
                cellDesktop: (prod) => (
                  <DesktopCellContentText
                    content={
                      <span className={styles.highlight}>
                        {formatCurrency(prod.price)}
                      </span>
                    }
                  />
                ),
              },
            ]}
          />
        </importForm.Field>
      </importForm.Form>
      <div style={{ marginTop: "auto" }}>
        {!!ctx.submission.error && ctx.getFieldError("products") && (
          <div className="p-2 text-center text-red mt-auto">
            {ctx.getFieldError("products")}
          </div>
        )}
        <hr />
        <div className={styles.footer}>
          <Button
            label="Close"
            variant="outlined"
            onClick={() => onClose()}
            className={styles.button}
            disabled={isMutating}
          />
          <Button
            label="Confirm"
            onClick={() => onNext()}
            className={styles.button}
            isLoading={isMutating}
          />
        </div>
      </div>
    </div>
  );
};
