import { BsFlag, BsFlagFill } from "react-icons/bs";
import styles from "./dev-tooltip.module.css";
import { CgClose } from "react-icons/cg";
import { useState } from "react";
import { Button } from "src/app/components/button";

export const DevTooltip = ({ displayed }: { displayed: boolean }) => {
  const [visible, setVisible] = useState(true);
  const toggleText = displayed ? "ON" : "OFF";
  const iconSize = visible ? 16 : 12;

  return (
    <>
      <div
        className={styles.infobox}
      >
        <Button
          variant="ghost"
          icon={
            displayed ? (
              <BsFlagFill size={iconSize} />
            ) : (
              <BsFlag size={iconSize} />
            )
          }
          tooltip={`Feature is ${toggleText}`}
          onClick={() => setVisible((v) => !v)}
        />

        {!!visible && (
          <>
            <span>Feature is&nbsp;</span>
            <b>{toggleText}</b>

            <Button
              variant="ghost"
              icon={<CgClose />}
              tooltip="Close"
              onClick={() => setVisible(false)}
            />
          </>
        )}
      </div>
    </>
  );
};
