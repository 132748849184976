import { FetcherOptions, createFetcher, isResponseError } from "up-fetch";
import { STORAGE_KEY } from "../const";
import { ZodError, ZodSchema, ZodTypeDef } from "zod";
import { path } from "../routes/path";
import { env } from "src/env";
import * as Sentry from "@sentry/react";

export const upfetch = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: `${env.REACT_APP_API_URL_NEW}v2/`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      onError(error) {
        if (isResponseError(error) && error.response.status === 401) {
          window.location.pathname = path.logout();
        }
      },
    };
  }),
);

export const upfetchV3 = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: `${env.REACT_APP_API_URL_NEW}api/v3/`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      parseThrownResponse: async (res) => {
        try {
          const body = await res.json();
          return body;
        } catch (e) {
          return { message: "Unexpected Error" };
        }
      },
      throwWhen: (response) => {
        return response.status < 200 || response.status > 299;
      },
      onError(error) {
        if (isResponseError(error) && error.response.status === 401) {
          window.location.pathname = path.logout();
        }
      },
    };
  }),
);

export const upfetchV2New = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: `${env.REACT_APP_API_URL_NEW}api/v2_1/`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      onError(error) {
        if (isResponseError(error) && error.response.status === 401) {
          window.location.pathname = path.logout();
        }
      },
    };
  }),
);

export const upfetchV1 = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: env.REACT_APP_API_URL_NEW,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      params: accessToken ? { accessToken } : {},
      onError(error) {
        if (isResponseError(error) && error.response.status === 401) {
          window.location.pathname = path.logout();
        }
      },
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      throwWhen: async (res) => {
        try {
          const { code } = await res.clone().json();

          return (!!code && code !== 200) || (res.status < 200 || res.status > 299);
        } catch (e) {
          return true;
        }
      },
    };
  }),
);

export const upfetchV1New = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: env.REACT_APP_API_URL_NEW,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      onError(error) {
        if (isResponseError(error) && error.response.status === 401) {
          window.location.pathname = path.logout();
        }
      },
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      throwWhen: async (res) => {
        try {
          const { code } = await res.clone().json();
          return code !== 200;
        } catch (e) {
          return true;
        }
      },
    };
  }),
);

function withZod<TD = any>(
  fetcher: (options?: FetcherOptions<TD>) => Promise<TD>,
) {
  async function fetcherWithZod<D = TD, I = D, O = I>(
    options: FetcherOptions<D> & { schema?: ZodSchema<I, ZodTypeDef, O> },
  ) {
    const data = await fetcher(options as any);
    if (env.isDev || !env.isProd || env.REACT_APP_VERCEL_ENV === "preview") {
      try {
        return options.schema?.parse(data) || (data as I);
      } catch (error) {
        if (error instanceof ZodError) {
          logZodError(options, error);
        }
      }
      return data as I;
    } else {
      if (!options.schema) return data as I;
      const result = options.schema.safeParse(data);
      if (!result.success) {
        logZodError(options, result.error);
        Sentry.captureException(result.error);
        return data as I;
      }
      return result.data;
    }
  }

  return fetcherWithZod;
}

function logZodError(options: FetcherOptions, error: ZodError) {
  console.error(
    "%cINCOMPATIBLE CORE DATA:",
    "background: red; padding: 2px 4px 1px 4px; border-radius: 3px",
    "\n\nURL:",
    `${env.REACT_APP_API_URL_NEW}${options.url}`,
    "\n\nSEARCH PARAMS:",
    options.params,
    "\n\nBODY:",
    options.body,
    "\n\nERRORS",
    error.format(),
    "\n\nDETAILED",
    error.errors,
  );
  console.error(error.format());
}
