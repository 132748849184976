import { PAGE_TITLE } from "src/app/const";
import { PageStats } from "src/app/components/page-stats";
import { useMyAssetsStats } from "src/app/api/queries_to_rename_later";
import { TotalMarketValue } from "src/app/pages/dashboard/total-market-value";
import { Spacer } from "src/app/components/spacer";
import { WhatsNewSlider } from "./WhatsNewSlider";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { useMyUser } from "src/app/hooks/useMyUser";
import ConsentProfilingModal from "./consent-profiling-modal";
import { useState } from "react";

export function DashboardPage() {
  const user = useMyUser();

  usePageTitle(PAGE_TITLE.DASHBOARD(user));
  const statsQuery = useMyAssetsStats();
  const [isOpenConsentModal, setIsOpenConsentModal] = useState(
    !user.is_view_consent_profiling && !user.consent_profiling,
  );

  return (
    <section style={{ paddingTop: "30px" }}>
      {!!isOpenConsentModal && (
        <ConsentProfilingModal
          onClosed={() => setIsOpenConsentModal(false)}
          userId={user.id}
        />
      )}
      <TotalMarketValue />

      <Spacer size={32} />

      <PageStats stats={statsQuery.data} />

      <Spacer size={40} />

      <WhatsNewSlider />
    </section>
  );
}
