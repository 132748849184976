import { ReactNode } from "react";
import { Tooltip } from "src/app/components/tooltip";
import { toPx } from "src/app/utils/toPx";

type Props = {
  content: ReactNode;
  minWidth?: string | number;
  serif?: boolean;
  maxLines?: number;
};

export const MobileCellContentText = ({
  content,
  minWidth = "auto",
  serif = false,
  maxLines,
}: Props) => {
  return (
    <div
      style={{
        minWidth: toPx(minWidth),
        fontFamily: serif ? "EB Garamond" : "Inter",
        fontSize: serif ? "16px" : "13px",
        lineHeight: 1.25,
        fontWeight: serif ? 500 : 400,
      }}
    >
      <Tooltip delay={150} position="bottom" content={content}>
        <div
          style={{
            minHeight: maxLines ? `${1.375 * maxLines}em` : "auto",
            display: "flex",
            alignItems: "start",
          }}
        >
          {maxLines ? (
            <div
              style={{
                whiteSpace: "normal",
                lineClamp: maxLines,
                WebkitLineClamp: maxLines,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {content}
            </div>
          ) : (
            <div style={{ whiteSpace: "normal" }}>{content}</div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
