import styles from "../styles.module.css";
import { Fragment, useState } from "react";
import { minutes } from "src/app/utils/time";
import { ProvenanceSubRow } from "./provenance-subrow";
import { ResellActionSlide } from "../../../modules/Includes/CustomerSide/components/cellar/ResellActionSlide";
import { BulkActions } from "../bulk-actions";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { CellContentBottleCount } from "./cell-content-bottle-count";
import { path } from "src/app/routes/path";
import { Button } from "src/app/components/button";
import { inferDataType } from "src/app/utils/types";
import { ApiLotV1, LotAction } from "src/app/models/LotV1";
import {
  useMyLotsFiltersQuery,
  useMyLotsQuery,
} from "src/app/api/queries_to_rename_later";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { DesktopCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-desktop/cell-content-total-price";
import { MobileCellContentTotalPrice } from "src/app/components/table/_shared/cell-content-mobile/cell-content-total-price";
import { DesktopCellContentEmp } from "src/app/components/table/_shared/cell-content-desktop/cell-content-emp";
import { MobileCellContentEmp } from "src/app/components/table/_shared/cell-content-mobile/cell-content-emp";
import { CellContentStatus } from "./cell-content-status";
import { DesktopCellContentActions } from "src/app/components/table/_shared/cell-content-desktop/cell-content-actions";
import { MobileCellContentActions } from "src/app/components/table/_shared/cell-content-mobile/cell-content-actions";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Drawer } from "src/app/components/drawer";
import { Drawer as Drawer2 } from "src/app/components/drawer-2";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useTableSelection } from "src/app/components/table/use-table-selection";
import { A } from "src/app/components/a";
import { useDownload } from "src/app/hooks/useDownload";
import { useSlot } from "src/app/hooks/useSlot";
import { useRequestShipmentMutation } from "src/app/api/shipments";
import { ShipmentForm } from "src/app/components/shipment-form/shipment-form";
import SendGiftForm from "src/app/components/cellar-forms/send-gift-form";
import { useIsMutating } from "@tanstack/react-query";
import { CellarResellStatusSlide } from "src/app/modules/Includes/CustomerSide/components/cellar/cellar-resell-status-slide";
import { DialogExport } from "../connection/dialog-export";
import { DialogImport } from "../connection/dialog-import";
import { BsBoxSeam } from "react-icons/bs";
import { TableServerSide } from "src/app/components/table/table-server-side";
import { ID_LOT_STATUS } from "src/app/const";
import { useFilters } from "src/app/hooks/use-filters";
import { FilterDropdown } from "src/app/components/filter-dropdown";
import { useDeboucedInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useDeboucedInputAutocomplete";
import { getLotName } from "./get-lot-name";
import { FeatureFlag } from "src/feature-flag";
import { can } from "src/feature-flag/schema";

const getRowId = (lot: ApiLotV1) => `${lot.id}-${lot.status}`;
const CRUTRADE_STATUSES = [
  ID_LOT_STATUS.CRUTRADE_READY_TO_IMPORT,
  ID_LOT_STATUS.CRUTRADE_LISTED,
  ID_LOT_STATUS.CRUTRADE_PROCESSING,
];

export const PageLots = () => {
  const isMobile = useIsMobile();
  const user = useMyUser();
  const slot = useSlot();
  const [visibleExportOverlay, showExportOverlay] = useState(false);
  const [visibleImportOverlay, showImportOverlay] = useState(false);

  const isMutating = useIsMutating();

  const download = useDownload();

  const { filters, search } = useFilters();
  const lotsFilters = useMyLotsFiltersQuery(filters);
  const lotsQuery = useMyLotsQuery(
    {
      sortBy: search.sortBy,
      sortDir: search.sortDir,
      page: search.page,
      pageSize: search.pageSize,
      search: search.value,
    }, // TODO: fix
    filters,
  );

  const requestShipmentMutation = useRequestShipmentMutation();

  const selection = useTableSelection({
    getRowId,
    data: lotsQuery.data?.items,
  });

  const inputAutocomplete = useDeboucedInputAutocomplete({
    inferDataType: inferDataType<ApiLotV1>(),
    getSuggestionText: (lot) => [lot.auctions?.description || lot.description],
  });

  function openGiftDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer
        title="Gift an asset"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ close }) => (
          <SendGiftForm selectedLots={lots} onClose={() => close()} />
        )}
      />
    ));
  }

  function openShipDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer2
        title="Ship an asset"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ startClosing }) => (
          <ShipmentForm
            selectedLots={lots}
            mutateAsync={requestShipmentMutation.mutateAsync}
            submitButtonText="Send Request"
            onCancel={startClosing}
            onError={startClosing}
            onSuccess={startClosing}
          />
        )}
      />
    ));
  }

  function openResellStatusDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer2
        title={`Resell for ${lots[0]?.case_}`}
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={() => (
          //TODO: Rewrite all render components of this page
          <CellarResellStatusSlide id_lot={lots[0].id_lot} />
        )}
      />
    ));
  }

  function openResellCruratedDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer
        title="Resell Auction"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ close }) => (
          <ResellActionSlide
            offersSelected={lots}
            refresh={() => lotsQuery.refetch()}
            closeSlide={close}
          />
        )}
      />
    ));
  }

  function openCrutradeExportDialog(lots: ApiLotV1[]) {
    selection.set(lots.map((lot) => getRowId(lot)));
    showExportOverlay(true);
  }
  function openCrutradeImportDialog(lots: ApiLotV1[]) {
    selection.set(lots.map((lot) => getRowId(lot)));
    showImportOverlay(true);
  }

  const actionsCallbacks: Record<LotAction, (lots: ApiLotV1[]) => void> = {
    [LotAction.SHIP]: openShipDrawer,
    [LotAction.GIFT]: openGiftDrawer,
    [LotAction.RESELL]: openResellCruratedDrawer,
    [LotAction.RESELL_STATUS]: openResellStatusDrawer,
    [LotAction.IMPORT_TO_CRUTRADE]: openCrutradeImportDialog,
    [LotAction.EXPORT_TO_CRUTRADE]: openCrutradeExportDialog,
  };

  const getActions = (lot: ApiLotV1) => {
    return lot.actions
      .filter((action) => action.is_available)
      .map((action) => ({
        ...action,
        onClick: () => actionsCallbacks[action.value]([lot]),
      }));
  };

  const canAccessCrutrade = can.accessCrutrade(user);

  return (
    <Fragment>
      {slot.output}
      <FeatureFlag hasPermission={canAccessCrutrade}>
        {() => (
          <>
            {!!visibleExportOverlay && (
              <Drawer2
                title=""
                position="right"
                width={780}
                onClosed={() => showExportOverlay(false)}
                waitForMutation={false}
                render={({ startClosing }) => (
                  <DialogExport
                    lots={selection.data}
                    onClose={() => startClosing()}
                  />
                )}
              />
            )}
            {!!visibleImportOverlay && (
              <Drawer2
                title=""
                position="right"
                width={780}
                onClosed={() => showImportOverlay(false)}
                waitForMutation={false}
                render={({ startClosing }) => (
                  <DialogImport lots={selection.data} onClose={startClosing} />
                )}
              />
            )}
          </>
        )}
      </FeatureFlag>

      <div className={`${styles.controlBar} ${isMobile ? styles.mobile : ""}`}>
        <div className={styles.filters}>
          <FilterDropdown
            data={lotsFilters.data}
            isLoading={lotsFilters.isLoading}
          />
        </div>

        <InputAutocomplete
          data={lotsQuery.data?.items || []}
          value={inputAutocomplete.value}
          onChange={inputAutocomplete.setValue}
          getSuggestionText={inputAutocomplete.getSuggestionText}
        />

        <BulkActions
          style={{ width: "100%" }}
          selection={selection.rowIds}
          onClearSelection={selection.set}
          actions={[
            {
              label: "Gift",
              onClick: () => openGiftDrawer(selection.data),
              disabled:
                !selection.data.length ||
                !isActionEnabled(selection.data, LotAction.GIFT),
            },
            {
              label: "Ship",
              onClick: () => openShipDrawer(selection.data),
              disabled:
                !selection.data.length ||
                !isActionEnabled(selection.data, LotAction.SHIP),
            },
            +user.allow_resell_crurated === 1 && {
              label: "Resell for Crurated",
              onClick: () => openResellCruratedDrawer(selection.data),
              disabled:
                !selection.data.length ||
                !isActionEnabled(selection.data, LotAction.RESELL),
            },
            // {
            //   label: "Download Bottles Report",
            //   onClick: () => download.bottlesCSV(lotsQuery.data?.items),
            //   disabled: !lotsQuery.data?.total,
            // },
            canAccessCrutrade && {
              label: "Import from Crutrade",
              onClick: () => showImportOverlay(true),
              disabled:
                !selection.data.length ||
                !isActionEnabled(selection.data, LotAction.IMPORT_TO_CRUTRADE),
            },
            canAccessCrutrade && {
              label: "Export to Crutrade",
              onClick: () => showExportOverlay(true),
              disabled:
                !selection.data.length ||
                !isActionEnabled(selection.data, LotAction.EXPORT_TO_CRUTRADE),
            },
          ]}
        />
      </div>

      <TableServerSide
        urlPrefix=""
        enableSelectionForRow={(lot) =>
          !!lot.actions.filter((action) => action.is_available)
        }
        enableExpansionForRow={() => true}
        data={lotsQuery.data}
        getRowId={getRowId}
        isFetching={lotsQuery.isFetching}
        isLoading={lotsQuery.isLoading}
        minCellHeightDesktop="78px"
        noDataTitle="Your Cellar is empty"
        noDataContent={
          <div>
            <p>View live auctions to make a new bid.</p>
            <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
          </div>
        }
        renderSubRow={ProvenanceSubRow}
        selection={selection.rowIds}
        onChangeSelection={selection.set}
        columns={[
          {
            header: "Lot Name",
            id: "description",
            cellDesktop: (lot) => (
              <DesktopCellContentText
                content={getLotName(lot, false)}
                serif
                minWidth="200px"
                maxLines={2}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentText content={getLotName(lot, false)} serif />
            ),
          },
          {
            header: "Lot Qty.",
            id: "quantity",
            cellDesktop: (lot) =>
              CRUTRADE_STATUSES.includes(lot.statusNew.id) ? null : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsBoxSeam size={16} />
                  <span>&nbsp;x&nbsp;</span>
                  <span>{lot.serials.length}</span>
                </div>
              ),
          },
          {
            header: "Price per Lot",
            id: "price-per-lot",
            cellDesktop: (lot) =>
              CRUTRADE_STATUSES.includes(lot.statusNew.id) ? null : (
                <DesktopCellContentTotalPrice
                  total={lot.finance.pmc}
                  details={[{ label: "Excl. processing fees and taxes" }]}
                />
              ),
            cellMobile: (lot) => (
              <MobileCellContentTotalPrice
                total={lot.finance.pmc}
                details={[
                  {
                    label: (
                      <span style={{ fontStyle: "italic" }}>
                        Excl. processing fees and taxes
                      </span>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            header: "Total Btl.",
            id: "bottles",
            cellDesktop: (lot) => <CellContentBottleCount lot={lot} />,
          },
          {
            header: "Total",
            id: "total",
            alignHeaderDesktop: "center",
            cellDesktop: (lot) => (
              <DesktopCellContentTotalPrice
                paid={lot.paid}
                details={[
                  {
                    amount: lot.finance.total,
                    label: "price",
                  },
                  {
                    amount: lot.finance.total_insurance,
                    label: "processing fee",
                  },
                ]}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentTotalPrice
                paid={lot.paid}
                details={[
                  { amount: lot.finance.total, label: "price" },
                  {
                    amount: lot.finance.total_insurance,
                    label: "processing fee",
                  },
                ]}
              />
            ),
          },
          {
            header: "EMP per Lot",
            id: "emp",
            alignHeaderDesktop: "center",
            cellDesktop: (lot) => (
              <DesktopCellContentEmp
                emp={lot.finance.average_emp}
                ratio={lot.finance.ratio}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentEmp
                emp={lot.finance.average_emp}
                ratio={lot.finance.ratio}
              />
            ),
          },

          {
            header: "Status",
            id: "status",
            cellDesktop: (lot) => (
              <div>
                <CellContentStatus
                  status={lot.statusNew}
                  available_date={lot.available_date}
                />

                {!!lot.gift && (
                  <div
                    className={`${styles.giftRibbon} ${
                      isMobile ? styles.mobile : ""
                    }`}
                  >
                    <div>Gift from {lot.gift.sender.personalData.fullName}</div>
                  </div>
                )}
              </div>
            ),
          },
          {
            header: "Actions",
            id: "actions",
            cellDesktop: (lot) => (
              <DesktopCellContentActions
                disabled={lotsQuery.isFetching}
                actions={getActions(lot)}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentActions
                disabled={lotsQuery.isFetching}
                actions={getActions(lot)}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

function isActionEnabled(lots: ApiLotV1[], requiredAction: LotAction) {
  return lots.every(
    (lot) =>
      lot.actions.find((action) => action.value === requiredAction)
        ?.is_available,
  );
}
