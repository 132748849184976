import styles from "./index.module.css";
import { RadioGroup } from "@headlessui/react";
import { AddressV1 } from "src/app/models/Address";
import { orderBy } from "lodash";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useUpdateEffect } from "react-use";
import { cn } from "src/app/utils/cn";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { useMyUserQuery } from "src/app/api/auth";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

// type Props = {
//   onChange: (value: AddressV1) => void;
//   selectedAddress?: AddressV1;
//   initialSelectedAddress?: AddressV1;
// };

export const InvoiceAddress = () => {
  const myUser = useMyUser();

  const addressInvoiceList = orderBy(
    myUser.addressInvoice,
    ["created_date", "confirmed", "active"],
    ["desc", "desc", "desc"],
  );

  const selectedAddress = myUser.addressInvoice.find((addr) => addr.selected);

  return (
    <div>
      <RadioGroup
        className={styles.radioGroup}
        value={selectedAddress?.id_address}
      >
        {addressInvoiceList.length ? (
          addressInvoiceList
            // TODO: verify
            //.filter((addr) => addr.active && addr.confirmed)
            .map((address) => (
              <RadioGroupAddressOption
                key={`address.${address.id_address}`}
                address={address}
              />
            ))
        ) : (
          <p>No address found</p>
        )}
      </RadioGroup>
    </div>
  );
};

export const RadioGroupAddressOption = ({
  address,
  pendingVerification = false,
}: {
  address: AddressV1;
  pendingVerification?: boolean;
}) => {
  const myUser = useMyUser();
  const { isFetching: isFetchingAddresses } = useMyUserQuery();

  return (
    <RadioGroup.Option
      value={address.id_address}
      className={({ checked }) =>
        cn(styles.radioOption, checked && styles.checked)
      }
      disabled={pendingVerification}
    >
      {({ checked }) => (
        <>
          <div className={styles.radio}>
            {isFetchingAddresses ? (
              <SpinnerSm />
            ) : checked ? (
              <FaCheckCircle size={20} />
            ) : (
              <FaRegCircle size={20} />
            )}
          </div>
          <p className={styles.label}>{myUser.fullname}</p>
          <RadioGroup.Label as="p" className={styles.radioDescription}>
            <span>{address.address.city}</span>&nbsp;
            <span>{address.address.region}</span>&nbsp;
            <span>{address.address.country.countryISO}</span>
          </RadioGroup.Label>
        </>
      )}
    </RadioGroup.Option>
  );
};
