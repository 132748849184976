import styles from "./cellar-resell-status-slide.module.css";
import { useGetHandlingResellData } from "src/app/api/queries_to_rename_later";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { DesktopCellContentNumber } from "src/app/components/table/_shared/cell-content-desktop/cell-content-numbers";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";

const getRowId = (row: any) => row.id;

export function CellarResellStatusSlide({ id_lot }: { id_lot: number }) {
  const {
    data: resellData,
    isLoading,
    isFetching,
  } = useGetHandlingResellData(id_lot);

  return (
    <TableCustomerSide
      urlPrefix=""
      getRowId={getRowId}
      noDataContent=""
      data={resellData}
      isLoading={isLoading}
      isFetching={isFetching}
      minCellHeightDesktop="100px"
      noDataTitle="No resell found"
      fillMissingRows={false}
      columns={[
        {
          alignHeaderDesktop: "center",
          id: "id_lot",
          header: "Id",
          width: 2,
          cellDesktop: (resell) => (
            <DesktopCellContentNumber number={resell.id} />
          ),
        },
        {
          id: "quantity_resell",
          header: "Qty Resell",
          alignHeaderDesktop: "center",
          width: 2,
          getSortingValue: (resell) => resell.quantity_resell,
          cellDesktop: (resell) => (
            <DesktopCellContentNumber number={resell.quantity_resell} />
          ),
        },
        {
          id: "quantity_pending_sale",
          header: "Qty Pending",
          alignHeaderDesktop: "center",
          width: 2,
          getSortingValue: (resell) => resell.quantity_pending_sale,
          cellDesktop: (resell) => (
            <DesktopCellContentNumber number={resell.quantity_pending_sale} />
          ),
        },
        {
          id: "quantity_sold",
          header: "Qty Sold",
          alignHeaderDesktop: "center",
          width: 2,
          getSortingValue: (resell) => resell.quantity_sold,
          cellDesktop: (resell) => (
            <DesktopCellContentNumber number={resell.quantity_sold} />
          ),
        },
        {
          id: "status",
          header: "Status",
          alignHeaderDesktop: "center",
          width: 2,
          getSortingValue: (resell) => resell.closed,
          cellDesktop: (resell) => (
            <DesktopCellContentText
              content={
                resell.closed ? (
                  <div className={styles.closed}>Closed</div>
                ) : (
                  <div>
                    <div className={styles.open}>Open</div>
                  </div>
                )
              }
              serif
            />
          ),
        },
      ]}
    />
  );
}
