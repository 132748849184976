import { defineForm } from "crustack/form";
import { StepBottles } from "./step-bottles";
import {
  useImportFromCrutradeMutation,
} from "src/app/api/crutrade";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { useQueryClient } from "@tanstack/react-query";
import { ApiLotV1 } from "src/app/models/LotV1";

type Props = {
  lots: ApiLotV1[] | undefined;
  onClose: () => void;
};

export const importForm = defineForm<
  {
    products: Array<string | number>;
  },
  unknown,
  unknown
>();

export const DialogImport = ({ lots = [], onClose }: Props) => {
  const alertPopup = useAlertPopup();
  const queryClient = useQueryClient();

  const importMutation = useImportFromCrutradeMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Transfer in progress",
        content: (
          <div>
            <span>Your request to export from Crutrade was received.</span>
            <br />
            <span>You can see the status updated in real time.</span>
          </div>
        ),
        closeButton: {
          label: "Close",
        },
        onClose: () => queryClient.invalidateQueries(),
      });
    },
    onError: (error) => {
      alertPopup.error({
        title: "Transfer error",
        content: error.error,
        onClose: () => queryClient.invalidateQueries(),
      });
    },
    onSettled: onClose,
  });

  const availableProducts = lots.flatMap((lot) => lot.components);

  console.log(availableProducts)

  return (
    <importForm.Root
      initialValues={{
        products: availableProducts.map((prod) => prod.id_serial),
      }}
      onSubmit={(values) => {
        const selectedProducts = availableProducts.filter((el) =>
          values.products.includes(el.id_serial),
        );
        importMutation.mutate({ products: selectedProducts });
      }}
    >
      {(ctx) => (
        <StepBottles products={availableProducts} onNext={() => ctx.tryToSubmit()} onClose={onClose} />
      )}
    </importForm.Root>
  );
};
