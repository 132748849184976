import { z } from "zod";

export const apiCountrySchema = z.object({
  name: z.string(),
  id: z.number(),
  id_country: z.number(),
  countryISO: z.string(),
  isEnableForShipping: z.boolean(),
  EA: z.boolean(),
  // phoneCode: z.string(),
  // deleted: z.boolean(),
});

export type ApiCountry = z.infer<typeof apiCountrySchema>;

export class Country {
  name: string;
  id: number;
  id_country: number;
  countryISO: string;
  isEnableForShipping: boolean;
  EA: boolean;
  // phoneCode: string;
  // deleted: boolean;

  constructor(apiCountry: ApiCountry) {
    this.name = apiCountry.name;
    this.id = apiCountry.id;
    this.id_country = apiCountry.id_country;
    this.countryISO = apiCountry.countryISO;
    this.isEnableForShipping = apiCountry.isEnableForShipping;
    this.EA = apiCountry.EA;
    // this.phoneCode = apiCountry.phoneCode;
    // this.deleted = apiCountry.deleted;
  }
}
