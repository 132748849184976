import { z } from "zod";
import { ID_ROLE, ID_TIER } from "../const";
import { addressSchemaV1 } from "./Address";
import { booleanishSchema } from "./_booleanish-schema";

const userWarningSchemaV1 = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("warning"),
    id: z.number().or(z.string()),
    linkText: z.string(),
    linkTo: z.string(),
    message: z.string(),
  }),
  z.object({
    type: z.literal("modal"),
    id: z.number().or(z.string()),
    message: z.string(),
    linkText: z.string(),
    modal: z.literal("password-change"),
  }),
]);

export const userSubscriptionSchemaV1 = z
  .object({
    active: booleanishSchema,
    coupon_code: z.string().nullable(),
    created_date: z.string(),
    date_end_subscription: z.string(),
    date_end_trial: z.string().nullable(),
    date_on_end_trial: z.string(),
    date_start_trial: z.string().nullable(),
    date_subscription: z.string(),
    description: z.string().nullable(),
    full_price: z.number(),
    id_customer_role: z.number(),
    id_customer_role_now: z.number(),
    id_customer_role_old: z.number(),
    id_price_stripe: z.string(),
    id_subscription: z.number(),
    id_subscription_handling: z.number(),
    id_subscription_stripe: z.string(),
    id_type_subscription: z.number(),
    id_user: z.number(),
    initial_fee: z.number(),
    is_expired: z.boolean(),
    is_trial: booleanishSchema,
    name: z.string(),
    price: z.number(),
    processed: booleanishSchema,
    recurring_type: z.string(),
    sorting: z.number(),
    status: z.number(),
    unspent_budget: z.number(),
  })
  .nullable();

const userMethodPaymentSchemaV1 = z
  .object({
    active: z.object({
      name: z.string(),
      id_method_payment: z.number(),
    }),
    pending: z
      .object({
        slug: z.string(),
        handling_date_frontend: z.string(),
      })
      .or(z.literal(false)),
    permissions: z.object({
      is_enabled: z.boolean(),
    }),
  })
  .nullable()
  .transform((value) => {
    return Array.isArray(value) ? undefined : value;
  });

export const apiUserSchemaV1 = z.object({
  B2B: booleanishSchema,
  accessToken: z.string(),
  active: booleanishSchema,
  addressInvoice: z.array(addressSchemaV1),
  addressKey: z.string(),
  addressShipping: z.array(addressSchemaV1),
  allow_bid: booleanishSchema,
  allow_donate: booleanishSchema,
  allow_executive: booleanishSchema,
  allow_gift: booleanishSchema,
  allow_notifications: booleanishSchema,
  allow_resell_crurated: booleanishSchema,
  allow_resell_marketplace: booleanishSchema,
  authToken: z.string(),
  birthday: z.string().nullable(),
  company_name: z.string().nullable(),
  consent_marketing: z.boolean(),
  consent_profiling: z.boolean(),
  created_date: z.string(),
  deleted: booleanishSchema,
  description: z.string().nullable(),
  email: z.string(),
  excise_number: z.string(),
  expire_token: z.string(),
  first_name: z.string(),
  fullname: z.string(),
  id: z.number(),
  id_ac: z.string(),
  id_customer_role: z.nativeEnum(ID_TIER).nullable(),
  id_customer_stripe: z.string(),
  id_customer_xero: z.string(),
  id_manager: z.number(),
  id_producer: z.number(),
  id_tag_ac_barrel: z.number(),
  id_tag_ac_lot: z.number(),
  id_user: z.number(),
  id_user_mp: z.string(),
  id_user_wordpress: z.number(),
  is_american_tax: booleanishSchema.nullable(),
  is_created_backend: booleanishSchema,
  is_enabled_tech_fee: z.number(),
  is_invited: booleanishSchema,
  is_out_subscription: booleanishSchema,
  is_view_consent_profiling: z.boolean().optional(),
  kyc_status: z.string(),
  language: z.string(),
  last_name: z.string(),
  link_video: z.string(),
  max_import: z.number(),
  max_import_calculated: z.union([z.literal("NO"), z.string()]),
  method_payment: userMethodPaymentSchemaV1,
  note_b2b: z.string().nullable(),
  password: z.string(),
  phone: z.string(),
  pic: z.string(),
  privateKey: z.string(),
  professional: booleanishSchema.nullable(),
  professional_coefficient: z.string(),
  professional_desc: z.string().nullable(),
  quality_score: z.number(),
  ref: z.string().nullable(),
  request_downgrade: z.any(), // TODO ?
  roles: z.nativeEnum(ID_ROLE),
  send_data_to_gb: booleanishSchema.nullable(),
  subscription: userSubscriptionSchemaV1,
  tech_fee: z.number(),
  timeZone: z.string(),
  time_zone: z.string(),
  to_initialize: booleanishSchema,
  type_customer: z.string(),
  type_user: z.string(),
  vat: z.string(),
  wallet: z.number(),
  role: z.object({
    id: z.nativeEnum(ID_ROLE),
    id_roles: z.nativeEnum(ID_ROLE),
    name: z.string(),
    type: z.string(),
  }),
  personalData: z.object({
    id: z.number(),
    id_user: z.number(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    company_name: z.string().nullable(),
    birthday: z.string().nullable(),
    phone: z.string().nullable(),
    vat: z.string().nullable(),
    fullName: z.string(),
  }),
  checkUser: z.object({
    is_missing_card: booleanishSchema,
    totalPriceInOutstandingPayment: z.number(),
    errors: z.object({
      messageErrorExpiredDays: z.string(),
      messageErrorTooManyOutstandingPayment: z.string(),
    }),
    warnings: z.array(userWarningSchemaV1),
  }),
  has_pending_gifts: z
    .object({
      status: z.boolean(),
      qty: z.number(),
    })
    .optional(),
});

export type UserV1 = z.infer<typeof apiUserSchemaV1>;
