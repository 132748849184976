import styles from "./index.module.css";
import { noop } from "lodash";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";

export const ArrowExpand = ({
  disabled,
  isExpanded,
  onClick = noop,
}: {
  disabled: boolean | undefined;
  isExpanded: boolean | undefined;
  onClick?: () => void;
}) => {
  const isMobile = useIsMobile();
  return (
    <button
      className={cn(styles.arrowExpand, isMobile && styles.mobile)}
      onClick={() => onClick()}
      disabled={disabled}
      type="button"
    >
      <div
        className={`${styles.triangle} ${!isExpanded ? styles.rotate90 : ""}`}
      />
      <span>{!!isMobile && "Discover More"}</span>
    </button>
  );
};
