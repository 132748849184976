import styles from "./cell-content-status.module.css";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Tooltip } from "src/app/components/tooltip";
import { ID_LOT_STATUS } from "src/app/const";
import { formatDate } from "src/app/utils/formatDate";
import { ApiLotV1 } from "src/app/models/LotV1";

type Props = {
  status: ApiLotV1["statusNew"];
  available_date: string | null | undefined;
};

export function CellContentStatus({ status, available_date = null }: Props) {
  const isMobile = useIsMobile();

  return (
    <div
      className={
        Object.values(ID_LOT_STATUS).includes(status.id)
          ? styles[`status_id_${status.id}`]
          : styles.status_generic
      }
      style={{
        cursor: "default",
        whiteSpace: "nowrap",
        width: isMobile ? "auto" : "100%",
        fontSize: "12px",
        margin: 0,
      }}
    >
      {status.id === ID_LOT_STATUS.WITH_PRODUCER ? (
        <LabelWithArrivalDate
          text={status.label}
          arrivalDate={available_date}
        />
      ) : status.id === ID_LOT_STATUS.CRUTRADE_PROCESSING ? (
        <LabelWithSpinner text={status.label} />
      ) : (
        status.label
      )}
    </div>
  );
}

function LabelWithArrivalDate({
  text = "",
  arrivalDate,
}: {
  text: string;
  arrivalDate?: string | null;
}) {
  return (
    <Tooltip
      delay={150}
      position="bottom"
      content={
        <>
          Available on
          <br />
          {formatDate(arrivalDate, {
            day: undefined,
          }) || "-"}
        </>
      }
    >
      <div>
        <span>{text}</span> / <IconTruck />
      </div>
    </Tooltip>
  );
}

function IconTruck() {
  return (
    <svg
      style={{ marginLeft: "6px" }}
      width="20"
      height="17"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C8.53043 14 9.03914 13.7893 9.41421 13.4142C9.78929 13.0391 10 12.5304 10 12C10 11.4696 9.78929 10.9609 9.41421 10.5858C9.03914 10.2107 8.53043 10 8 10C7.46957 10 6.96086 10.2107 6.58579 10.5858C6.21071 10.9609 6 11.4696 6 12C6 12.5304 6.21071 13.0391 6.58579 13.4142C6.96086 13.7893 7.46957 14 8 14V14ZM18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10C17.4696 10 16.9609 10.2107 16.5858 10.5858C16.2107 10.9609 16 11.4696 16 12C16 12.5304 16.2107 13.0391 16.5858 13.4142C16.9609 13.7893 17.4696 14 18 14V14Z"
        stroke="currentColor"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.05 12H15V1.6C15 1.44087 14.9368 1.28826 14.8243 1.17574C14.7117 1.06321 14.5591 1 14.4 1H1M5.65 12H3.6C3.52121 12 3.44319 11.9845 3.37039 11.9543C3.29759 11.9242 3.23145 11.88 3.17574 11.8243C3.12002 11.7685 3.07583 11.7024 3.04567 11.6296C3.01552 11.5568 3 11.4788 3 11.4V6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M2 4H6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4H20.61C20.726 4.00003 20.8395 4.03367 20.9367 4.09685C21.034 4.16003 21.1108 4.25005 21.158 4.356L22.948 8.384C22.9821 8.46048 22.9998 8.54326 23 8.627V11.4C23 11.4788 22.9845 11.5568 22.9543 11.6296C22.9242 11.7024 22.88 11.7685 22.8243 11.8243C22.7685 11.88 22.7024 11.9242 22.6296 11.9543C22.5568 11.9845 22.4788 12 22.4 12H20.5M15 12H16"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}

function LabelWithSpinner({ text = "" }: { text: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconSpinner />
      <span>{text}</span>
    </div>
  );
}

function IconSpinner() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99953 17.3139C8.27442 17.1487 7.57791 16.8765 6.93286 16.5064M12.3329 2.68555C13.9897 3.06394 15.4689 3.99362 16.5284 5.32239C17.5879 6.65115 18.1649 8.30026 18.1649 9.99971C18.1649 11.6992 17.5879 13.3483 16.5284 14.677C15.4689 16.0058 13.9897 16.9355 12.3329 17.3139M4.48203 14.2439C4.02794 13.5832 3.68273 12.8539 3.45953 12.0839M3.26953 8.74971C3.40286 7.95805 3.65953 7.20805 4.01953 6.52055L4.16036 6.26638M6.42203 3.81555C7.2022 3.27961 8.07676 2.8962 8.99953 2.68555"
        stroke="#656565"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16602 10.0007L9.83268 11.6673L13.166 8.33398"
        stroke="#656565"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
