import styles from "./step-cases.module.css";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { ApiLotV1 } from "src/app/models/LotV1";
import { ApiProduct } from "src/app/models/Product";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { exportForm } from ".";
import { Select } from "src/app/components/form-elements/select";
import { TableColumnDef } from "src/app/components/table/types";
import {
  getGroupedLotsProducts,
  getProdId,
  parseQuantityLabels,
} from "./utilts";

type Props = {
  row: ApiLotV1;
  isMobile: boolean;
};

export type QuantityOption = {
  value: number;
  label: string;
  description: string;
};

export const ProductSubrow = ({ row, isMobile }: Props) => {
  const groupedProducts = getGroupedLotsProducts(row);

  return (
    <tr>
      <td colSpan={100} className={styles.subrow}>
        <TableCustomerSide
          urlPrefix="ld."
          enablePagination={false}
          isFetching={false}
          isLoading={false}
          fillMissingRows={false}
          data={groupedProducts}
          // unique identifier per product (id_product is not unique)
          getRowId={(prod) => prod.id_serial}
          minCellHeightDesktop="64px"
          noDataTitle=""
          noDataContent={<div></div>}
          columns={getColumns(isMobile)}
        />
      </td>
    </tr>
  );
};

function getColumns(isMobile: boolean) {
  const ctx = exportForm.useFormCtx();

  const columns: Array<TableColumnDef<ApiProduct>> = [
    {
      header: "Bottle",
      id: "prod_case",
      cellDesktop: (prod) => (
        <DesktopCellContentText
          serif
          minWidth={200}
          maxLines={2}
          content={getProductName(prod)}
        />
      ),
      cellMobile: (prod) => (
        <MobileCellContentText serif content={getProductName(prod)} />
      ),
    },

    {
      header: "Qty.",
      id: "prod_quantity",
      width: "200px",
      cellDesktop: (prod) => {
        const selectableQuantities = parseQuantityLabels(prod);

        return (
          <exportForm.Field
            name={`products`}
            key={prod.id}
            validate={(value) => {
              const products = Object.values(value);

              if (products.length === 0) return "Select at least one lot";

              const totQuantity = products.reduce(
                (tot, quantity) => tot + (quantity?.value || 0),
                0,
              );

              if (totQuantity === 0) return "Select at least one bottle";
            }}
          >
            <Select
              // className={styles.select}
              selectedOption={ctx.getFieldValue("products")[getProdId(prod)]}
              options={selectableQuantities}
              onChange={(option) => {
                ctx.setFieldValue(`products`, (val) => ({
                  ...val.products,
                  [getProdId(prod)]: option,
                }));
                ctx.setFieldTouched(`products`, true);
              }}
              style={{
                width: "100%",
              }}
            />
          </exportForm.Field>
        );
      },
    },
    {
      header: "Price per bottle",
      id: "prod_total",
      width: "150px",
      cellDesktop: (prod) => (
        <DesktopCellContentText
          content={
            <span className={styles.highlight}>
              {formatCurrency(prod.price)}
            </span>
          }
        />
      ),
      cellMobile: (prod) => (
        <MobileCellContentText
          content={
            <span className={styles.highlight}>
              {formatCurrency(prod.price)}
            </span>
          }
        />
      ),
    },
  ];

  if (!isMobile) {
    columns.unshift({
      header: "",
      width: 40,
      id: "spacer",
      cellDesktop: () => <span></span>,
    });
  }

  return columns;
}

const getProductName = (product: ApiProduct) => {
  return [product.vintage, product.nameProductWithoutVintage]
    .filter(Boolean)
    .join(", ");
};
