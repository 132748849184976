import { ReactNode } from "react";
import { CheckUserProfileIssues } from "../components/check-user-profile-issues";
import { TopbarMobile } from "./topbar-mobile";
import { BottomNavMobile } from "./bottom-nav-mobile";
import { CookieBanner } from "../components/cookie-banner";

type Props = {
  children: ReactNode;
};

export const LayoutMobile = ({ children }: Props) => {
  return (
    <>

      <TopbarMobile />

      <div className="mobile-main-container" style={{ paddingBottom: 0 }}>
        <div className="rest-content" style={{ paddingTop: 0 }}>
          <div className="kyc-status-bar">
            <CheckUserProfileIssues />
          </div>
          {children}
        </div>
      </div>

      <CookieBanner style={{ bottom: "84px" }} />

      <BottomNavMobile />
    </>
  );

  // return (
  //   <>
  //     {!hideMobileHeader && <HeaderMobileNew />}

  //     <div className="mobile-main-container">
  //       {!layoutProps.contentExtended && (
  //         <>
  //           <div className="rest-content">
  //             <div className="kyc-status-bar">
  //               <CheckUserProfileIssues />
  //             </div>
  //             {children}
  //           </div>
  //         </>
  //       )}

  //       {!!layoutProps.contentExtended && { children }}
  //     </div>

  //     <BottomNavMobile />
  //     {/* <p>Mobile Skeleton</p> */}
  //   </>
  // );
};
