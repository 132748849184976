import {
  FilterResponseSchema,
  useSelectableFilters,
} from "src/app/hooks/use-selectable-filters";
import { SelectMultiple } from "../form-elements/select-multiple";
import { SelectMultipleValues } from "../form-elements/select-multiple/types";
import { useFilters } from "src/app/hooks/use-filters";

type Props = {
  data: FilterResponseSchema | undefined;
  isLoading: boolean;
  onChange?: (values: SelectMultipleValues) => void;
  placeholder?: string;
};

export const FilterDropdown = ({
  data,
  isLoading,
  onChange,
  placeholder = "Filters",
}: Props) => {
  const { filters, setFilters } = useFilters();
  const { values, options } = useSelectableFilters({ filters, data });

  return (
    <SelectMultiple
      isLoading={isLoading}
      placeholder={placeholder}
      values={values}
      onChange={(values) => {
        setFilters(values);
        onChange?.(values);
      }}
      options={options}
    />
  );
};
