import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useMyUser } from "../hooks/useMyUser";
import { upfetch, upfetchV1, upfetchV2New, upfetchV3 } from "./upfetch";
import { IdAuctionErrorType, TypeProduct } from "../const";
import { Auction } from "../models/auctions";
import { gaEventConfig, triggerGaEvent } from "../models/GaEvent";
import { env } from "src/env";
import { GiftFormValues } from "../components/cellar-forms/send-gift-form";
import { z } from "zod";
import { useLoginWithTokenMutation } from "./auth";
import { useHistory } from "react-router";
import { OutstandingPaymentV1 } from "../models/OutstandingPaymentV1";
import { useSystemConfig } from "../hooks/useSystemConfig";
import { toFormData } from "../utils/toFormData";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntentResult, StripeError } from "@stripe/stripe-js";

// TODO: REMOVE CATCH & FINALLY
export const useAgreeForCharityMutation = (options = {}) => {
  const { accessToken } = useMyUser();

  return useMutation({
    ...options,
    mutationFn: () =>
      upfetch({
        url: "users/acceptSendDataToGB/",
        params: { accessToken },
        parseResponse: () => null,
        parseThrownResponse: () => null,
      }).finally(() => ({ settled: true })),
  });
};

// TODO: REMOVE CATCH & FINALLY
export const useAmericanTaxPayerMutation = (options = {}) => {
  const { accessToken } = useMyUser();

  type Payload = { is_american_tax: boolean };

  return useMutation({
    ...options,
    mutationFn: ({ is_american_tax }: Payload) =>
      upfetch({
        url: "users/isAmericanTaxPayer/",
        method: "POST",
        params: {
          accessToken,
          is_american_tax,
        },
        parseResponse: () => null,
        parseThrownResponse: () => null,
      }).finally(() => ({ settled: true })),
  });
};

type MakeABidMutationPayload = {
  quantity: number;
  price: number;
  auction: Auction;
  buy_now?: boolean;
};

export const useMakeABidMutation = (
  options: UseMutationOptions<any, any, MakeABidMutationPayload> = {},
) => {
  const user = useMyUser();

  return useMutation({
    mutationFn: ({ price, quantity, auction, buy_now = false }) =>
      upfetch({
        url: "bets/bid/",
        method: "POST",
        body: {
          id: auction.id,
          type: auction.type_product,
          quantity, // per i barrel è i multiplo del MUL
          single_bid: price,
          id_user: user.id_user,
          buy_now,
        },
      }),
    ...options,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      triggerGaEvent(
        gaEventConfig.makeABid({
          auction: variables.auction,
          id_bet: data.id_bet,
          price: variables.price,
          quantity: variables.quantity,
        }),
      );
    },
  });
};

type EditABidMutationPayload = {
  quantity: number;
  price: number;
  bid_id: number;
  type_product: TypeProduct;
};

export const useEditABidMutation = (
  options: UseMutationOptions<any, any, EditABidMutationPayload> = {},
) => {
  return useMutation({
    mutationFn: ({ quantity, price, bid_id, type_product }) =>
      upfetch({
        url: "bets/edit/",
        method: "POST",
        body: {
          bets: [
            {
              type: type_product,
              id: bid_id,
              quantity,
              single_bid: price,
            },
          ],
        },
      }),
    ...options,
  });
};

export const useAddToArchiveMutation = (
  options: UseMutationOptions<Auction, any, Auction> = {},
) => {
  return useMutation({
    ...options,
    mutationFn: (auction) => {
      // TODO: proper optimistic update ?
      const currentIsArchive = auction.isArchive;
      auction.isArchive = !auction.isArchive;
      auction.isWishlist = false;
      return currentIsArchive
        ? upfetch({
            baseUrl: env.REACT_APP_SERVICES_API,
            url: `auctions/activities/archive/${auction.id_auction}/${auction.type_product}`,
            method: "DELETE",
          })
        : upfetch({
            baseUrl: env.REACT_APP_SERVICES_API,
            url: `auctions/activities/archive`,
            method: "POST",
            params: {
              id_auction: auction.id_auction,
              auction_type: auction.type_product,
              end_date: auction.finish_data,
            },
          });
    },
  });
};

export const useToggleWishlistMutation = (
  options: UseMutationOptions<Auction, any, Auction> = {},
) => {
  return useMutation({
    ...options,
    mutationFn: (auction) => {
      // TODO: proper optimistic update ?
      const currentIsWishlist = auction.isWishlist;
      auction.isWishlist = !auction.isWishlist;
      auction.isArchive = false;
      return currentIsWishlist
        ? upfetchV3({
            url: `wishlists/sales/${auction.id_auction}`,
            method: "DELETE",
          })
        : upfetchV3({
            url: `wishlists/sales/${auction.id_auction}`,
            method: "POST",
          });
    },
  });
};

type EnprimeurWaitingListPayload = {
  id_auction: number;
  id_user: number;
};

export const useAddEnprimeurToWaitingListMutation = (
  options: UseMutationOptions<any, any, EnprimeurWaitingListPayload> = {},
) => {
  return useMutation({
    mutationFn: ({ id_auction, id_user }) =>
      upfetch({
        url: `api/v2_1/auctions/en-primeur/${id_auction}/interests`,
        method: "POST",
        baseUrl: env.REACT_APP_API_URL_NEW,
        body: { id_user },
      }),
    ...options,
  });
};

export const useRemoveEnprimeurToWaitingListMutation = (
  options: UseMutationOptions<any, any, EnprimeurWaitingListPayload> = {},
) => {
  return useMutation({
    mutationFn: ({ id_auction, id_user }) =>
      upfetch({
        url: `api/v2_1/auctions/en-primeur/${id_auction}/interests`,
        method: "DELETE",
        baseUrl: env.REACT_APP_API_URL_NEW,
        body: { id_user },
      }),
    ...options,
  });
};

export const useChangePaymentMethodMutation = (
  options: UseMutationOptions<any, any, { id_method_payment: number }> = {},
) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: async ({ id_method_payment }) => {
      const res = await upfetchV1({
        url: `users/ChangeMethodPayment/`,
        method: "POST",
        body: {
          accessToken: user.accessToken,
          id_method_payment: id_method_payment,
        },
        parseResponse: async (res) => await res.json(),
      });

      if (res.code !== 200) {
        throw new Error(res.error);
      }
    },
  });
};

export const useSendGiftMutation = (
  options: UseMutationOptions<any, any, GiftFormValues> = {},
) => {
  return useMutation({
    ...options,
    mutationFn: async (formValues) => {
      const { country, ...restFormValues } = formValues;
      const payload = {
        ...restFormValues,
        recipient_id_country: country.id,
      };
      const res = await upfetchV1({
        url: `gifts/ActionGift/`,
        method: "POST",
        body: payload,
        parseResponse: async (res) => await res.json(),
      });

      if (res.code !== 200) {
        throw new Error(res.error);
      }
    },
  });
};

export const useRedeemAllGiftsMutation = (
  options: UseMutationOptions<any, any, any> = {},
) => {
  return useMutation({
    mutationFn: async () => {
      return await upfetchV1({
        url: "gifts/RedeemAllGifts/",
        method: "GET",
      });
    },
    ...options,
  });
};

export const useRedeemGiftMutation = (
  options: UseMutationOptions<any, any, { id_gift?: string | number }> = {},
) => {
  return useMutation({
    mutationFn: async ({ id_gift }) => {
      return await upfetchV1({
        url: "gifts/RedeemGifts/",
        method: "POST",
        body: { id_gift },
        throwWhen: (res) => res.status !== 200,
        parseThrownResponse: async (res) => {
          try {
            const { result } = await res.json();
            return result.error || "Unexpected error";
          } catch (e) {
            return "Unexpected Error";
          }
        },
      });
    },
    ...options,
  });
};

export const useLoginAndRedeemGiftMutation = (
  options: UseMutationOptions<
    any,
    any,
    { code: string; person: string | number; id_gift: string | number }
  > = {},
) => {
  const loginWithTokenMutation = useLoginWithTokenMutation();
  const redeemGiftMutation = useRedeemGiftMutation();
  const history = useHistory();

  return useMutation({
    ...options,
    mutationFn: async ({ code, person, id_gift }) => {
      const user = await upfetchV1({
        url: "users/ActivateAccount/",
        method: "POST",
        body: {
          code,
          person,
        },
        parseThrownResponse: async (res) => {
          try {
            const { error } = await res.json();
            return error || "Unexpected error";
          } catch (e) {
            return "Unexpected Error";
          }
        },
        schema: z.record(z.any()).optional(),
      });

      if (!user) {
        return history.push("/signup");
      }

      await loginWithTokenMutation.mutateAsync({
        accessToken: user.accessToken,
      });

      return await redeemGiftMutation.mutateAsync({ id_gift });
    },
  });
};

export const usePayOutstandingPaymentsMutation = (
  options: UseMutationOptions<
    any,
    any,
    { total_pay: number; auctions_participant: OutstandingPaymentV1[] }
  > = {},
) => {
  return useMutation({
    mutationFn: (payload) =>
      upfetchV1({
        url: "myCellar/PaymentAuctionNew/",
        method: "POST",
        body: payload,
      }),
    ...options,
  });
};

export const useMarkOutstandingPaymentAsPaidMutation = (
  options: UseMutationOptions<
    any,
    any,
    { auctions_participant: OutstandingPaymentV1[] }
  > = {},
) => {
  return useMutation({
    mutationFn: (payload) =>
      upfetchV1({
        url: "myCellar/MarkPaidNew/",
        method: "POST",
        body: payload,
      }),
    ...options,
  });
};

export const useSendMailInfoMutation = (
  options: UseMutationOptions<
    any,
    any,
    {
      purpose: string;
      import: number;
      dataBankTransfer?: NonNullable<
        ReturnType<typeof useSystemConfig>
      >["dataBankTransfer"];
    }
  > = {},
) => {
  return useMutation({
    mutationFn: async (payload) => {
      return await upfetchV1({
        url: "myCellar/SendInformationViaEmail/",
        method: "POST",
        body: payload,
      });
    },
    ...options,
  });
};

export type UpdateUserPayload = {
  pic?: File;
  first_name: string;
  last_name: string;
  company_name: string;
  birthday: string;
  vat?: string;
  email: string;
  consent_profiling: boolean;
  consent_marketing: boolean;
};

export const useUpdateMyUserMutation = (options = {}) => {
  const user = useMyUser();

  return useMutation<unknown, Error, UpdateUserPayload>({
    ...options,
    mutationFn: ({
      pic,
      first_name,
      last_name,
      company_name,
      birthday,
      vat,
      email,
      consent_profiling,
      consent_marketing,
    }) => {
      const formData = toFormData({
        pic,
        first_name,
        last_name,
        company_name,
        birthday,
        vat,
        email,
        consent_profiling,
        consent_marketing,
        id_manager: user.id_user,
        id_user_profile: user.id_user,
        accessToken: user.accessToken,
      });

      return upfetchV1({
        url: "users/EditPersonalInformationUser/",
        method: "POST",
        body: formData,
      });
    },
  });
};

export type PaymentIntentPayload = {
  buy_now: boolean;
  id: number;
  id_user: number;
  quantity: number;
  type: string;
  is_shop: boolean;
  is_wallet: boolean;
  shipping_address: {
    name: string;
    address1: string;
    locality: string;
    postal_code: string;
    country_code: string;
    region: string;
  };
};

export const usePaymentIntentMutation = (
  options: UseMutationOptions<any, any, PaymentIntentPayload> = {},
) => {
  return useMutation({
    mutationFn: (payload: PaymentIntentPayload) =>
      upfetchV1({
        throwWhen: (res) => {
          return res.status !== 200;
        },
        parseThrownResponse: async (res) => {
          try {
            const response = await res.json();
            return response;
          } catch (e) {
            return { message: e };
          }
        },
        method: "POST",
        body: payload,
        url: "stripe/payment-intents",
        schema: z.object({
          clientSecret: z.string(),
          paymentIntentId: z.string(),
        }),
      }),
    ...options,
  });
};

export const usePaymentAuthenticationMutation = (
  options: UseMutationOptions<any, any, PaymentIntentResult> = {},
) => {
  const stripe = useStripe();

  return useMutation({
    mutationFn: async (intentResponse: PaymentIntentResult) => {
      if (!stripe) return intentResponse;

      const { paymentIntent } = intentResponse;
      if (!paymentIntent?.client_secret) {
        throw new Error("Payment error");
      }

      if (paymentIntent.next_action?.type === "use_stripe_sdk") {
        return await stripe.handleCardAction(paymentIntent.client_secret);
      }

      return intentResponse;
    },
    ...options,
  });
};

export const useConfirmPaymentMutation = (
  options: UseMutationOptions<
    PaymentIntentResult,
    StripeError,
    PaymentIntentPayload
  > = {},
) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentIntentMutation = usePaymentIntentMutation();
  const paymentAuth = usePaymentAuthenticationMutation();

  return useMutation<PaymentIntentResult, StripeError, PaymentIntentPayload>({
    mutationFn: async (payload: PaymentIntentPayload) => {
      if (!stripe || !elements) return;
      const response = await paymentIntentMutation
        .mutateAsync(payload)
        .catch((e) => {
          if (e.message) {
            return {
              paymentIntent: undefined,
              error: { message: e.message },
            };
          }
        });

      if (!response.clientSecret) return response;

      // Confirm the patment client.side using Stripe API
      const confirmResponse = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        clientSecret: response.clientSecret,
        confirmParams: {
          shipping: {
            name: payload.shipping_address.name,
            address: {
              line1: payload.shipping_address.address1,
              city: payload.shipping_address.locality,
              postal_code: payload.shipping_address.postal_code,
              country: payload.shipping_address.country_code,
            },
          },
          return_url: window.location.href,
        },
      });

      if (confirmResponse.error) {
        return confirmResponse;
      }

      // Attempt any further required
      return await paymentAuth.mutateAsync(confirmResponse);
    },
    ...options,
  });
};

export type CheckBidErrorResponse = Array<{
  type: IdAuctionErrorType;
  message: string;
}>;

type CheckBidPayload = {
  id_auction: number;
  auction_type: TypeProduct;
  method: PurchaseMethod;
};

export type PurchaseMethod = "buy_now" | "place_bid" | "edit_bid";

export const useCheckBidMutation = (
  options: UseMutationOptions<any, CheckBidErrorResponse, CheckBidPayload> = {},
) => {
  return useMutation<[], CheckBidErrorResponse, CheckBidPayload>({
    mutationFn: async ({
      id_auction,
      auction_type,
      method,
    }: {
      id_auction: number;
      auction_type: TypeProduct;
      method: PurchaseMethod;
    }) => {
      return await upfetchV2New({
        method: "POST",
        throwWhen: (res) => {
          return res.status !== 200;
        },
        parseThrownResponse: async (res) => {
          try {
            const result = await res.json();
            return result;
          } catch (e) {
            return { message: e };
          }
        },
        url: `auctions/${id_auction}/auction-type/${auction_type}/action-type/${method}/check-bid`,
      });
    },
    ...options,
  });
};
