import { z } from "zod";
import { Product, apiProductSchema } from "./Product";

export const apiLotSchema = z.object({
  id: z.number(),
  id_lot: z.number(),
  photo: z.string().nullable(),
  sku: z.string(),
  case_: z.string(),
  // id_lot_mp: z.string(),
  available_date: z.string().nullable(),
  deleted: z.boolean(),
  id_warehouse: z.number(),
  description: z.string(),
  components: z.array(apiProductSchema),
});

export type ApiLot = z.infer<typeof apiLotSchema>;

export class Lot {
  id: number;
  id_lot: number;
  photo: string;
  sku: string;
  case_: string;
  // id_lot_mp: string;
  available_date: string | null;
  deleted: boolean;
  id_warehouse: number;
  description: string;
  components: Product[];

  constructor(apiLot: ApiLot) {
    this.id = apiLot.id;
    this.id_lot = apiLot.id_lot;
    this.photo = apiLot.photo ?? "";
    this.sku = apiLot.sku;
    this.case_ = apiLot.case_;
    // this.id_lot_mp = apiLot.id_lot_mp;
    this.available_date = apiLot.available_date;
    this.deleted = apiLot.deleted;
    this.id_warehouse = apiLot.id_warehouse;
    this.description = apiLot.description;
    this.components = apiLot.components.map(
      (apiProduct) => new Product(apiProduct),
    );
  }
}
