import { defineStorage } from "crustack/hooks";

export const { useLocalStorage, useClearLocalStorage } = defineStorage(
  {
    hideBannerDialog: {
      validate: (value) => !!value,
    },
    hideTutorial: {
      validate: (value) => !!value,
    },
  },
  { type: "local" },
);
