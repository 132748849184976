import { useMemo, useState } from "react";
import { RowId } from "./types";
import { useLatest } from "react-use";

const emptyArray: any[] = [];

export const useTableSelection = <TData extends object>({
  data = emptyArray,
  getRowId,
}: {
  data: TData[] | undefined;
  getRowId: (row: TData) => RowId;
}) => {
  const getRowIdRef = useLatest(getRowId);
  const [selection, setSelection] = useState<RowId[]>([]);

  const selectedRowIds = useMemo(() => {
    return data
      .filter((row) => selection.includes(getRowIdRef.current(row)))
      .map(getRowIdRef.current);
  }, [selection, data, getRowIdRef]);

  const selectedData = useMemo(() => {
    return data.filter((row) => selection.includes(getRowIdRef.current(row)));
  }, [selection, data, getRowIdRef]);

  return {
    rowIds: selectedRowIds,
    data: selectedData,
    set: setSelection,
  };
};
