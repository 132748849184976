import { ColId, TableColumnDef } from "./types";

export function getSortDir(
  sortBy: ColId | undefined,
  sortDir: "asc" | "desc" | undefined,
  col: TableColumnDef<any>,
) {
  if (col.id !== sortBy) {
    return "asc";
  }
  if (sortDir === "asc") {
    return "desc";
  }
  if (sortDir === "desc") {
    return;
  }
  return "asc";
}

export function getIsSortEnabled(col: TableColumnDef<any>) {
  return col.id && col.getSortingValue;
}
