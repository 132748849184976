import styles from "./index.module.css";
import { useIsMutating } from "@tanstack/react-query";
import { useBidProcessingFeesQuery } from "src/app/api/queries_to_rename_later";
import { Button } from "src/app/components/button";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { PaymentIntentResult } from "@stripe/stripe-js";
import { Subtotal } from "./subtotal";
import { ExpressCheckoutButton } from "../express-checkout-button";
import { MdOutlineLock } from "react-icons/md";
import { useMyCardsQuery } from "src/app/api/payments";
import { useMyUser } from "src/app/hooks/useMyUser";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import { RadioPaymentMethod } from "./radio-payment-method";
import { ID_PAYMENT_METHOD, IdPaymentMethod } from "src/app/const";

type Props = {
  title: string;
  auction: AuctionOfBarrel | AuctionOfLot;
  onClose: () => void;
  onAddNewCard: () => void;
  onConfirm: () => void;
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
  onPaymentSettled: (paymentOutcome: PaymentIntentResult | undefined) => void;
};

export const StepPaymentMethod = ({
  bidDetails,
  auction,
  title,
  showTaxDisclaimer,
  onClose,
  onAddNewCard,
  onConfirm,
  onPaymentSettled,
}: Props) => {
  const user = useMyUser();
  const { data: myCards = [] } = useMyCardsQuery();

  const userMethodPaymentDefault = user.method_payment?.active
    .id_method_payment as IdPaymentMethod;

  const hasBankTransferByDefault =
    userMethodPaymentDefault === ID_PAYMENT_METHOD.BANK;

  const { data: dataFees = undefined, isLoading: isLoadingFees } =
    useBidProcessingFeesQuery(
      {
        auction_type: auction.type_product,
        id_auction: auction.id,
        quantity: bidDetails?.quantity || 1,
        single_bid: bidDetails?.price || 1,
      },
      { enabled: !!bidDetails },
    );

  const isMutating = !!useIsMutating();
  const isMissingAddress = !user.addressInvoice.length;

  return (
    <div className={styles.content}>
      <div>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <Subtotal
        isLoadingFees={isLoadingFees}
        fees={dataFees}
        auction={auction}
        bidDetails={bidDetails}
        showTaxDisclaimer={showTaxDisclaimer}
      />
      <div className={styles.buttonsWrapper}>
        {!myCards.length ? (
          <p style={{ color: "#4B4A54", fontSize: "14px", textAlign: "left" }}>
            You don&apos;t have a credit card linked to your account. <br />
            If you want to pay with a credit card, please add one in your
            account&apos;s private area.
          </p>
        ) : isMissingAddress ? (
          <p style={{ color: "#4B4A54", fontSize: "14px", textAlign: "left" }}>
            You don&apos;t have an invoice address for your account. <br /> If
            you want to pay with a credit card, please add one in your
            account&apos;s private area.
          </p>
        ) : null}

        {!myCards.length ? (
          <Button
            as={"button"}
            variant="ghost"
            className={styles.button}
            label="+ Add a debit/credit card"
            disabled={isMutating}
            onClick={onAddNewCard}
            isLoading={isMutating}
          />
        ) : isMissingAddress ? (
          <Button
            as={A}
            variant="ghost"
            onClick={onClose}
            className={styles.button}
            to={path.profile.addresses}
            label="Continue to add new address"
          />
        ) : null}

        <div className={styles.rootRadio}>
          <RadioPaymentMethod
            userMethodPaymentDefault={userMethodPaymentDefault}
            onClose={onClose}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          width: "100%",
        }}
      >
        {(!!myCards.length || !!hasBankTransferByDefault) && (
          <div style={{ width: "100%" }}>
            <Button
              as={"button"}
              className={styles.buttonPay}
              label={
                hasBankTransferByDefault
                  ? "Pay with Bank Transfer"
                  : "Pay with Credit Card"
              }
              disabled={isMutating || isLoadingFees}
              onClick={onConfirm}
              isLoading={isMutating}
            />
          </div>
        )}
        {/* <FeatureFlag
          displayWhen={can.accessCrutrade(user)}
        >
          {() => ( */}
        {/* <> */}
        {!!bidDetails && !isNaN(dataFees) && (
          <ExpressCheckoutButton
            onPaymentSettled={onPaymentSettled}
            bidDetails={{
              auction,
              fees: dataFees,
              ...bidDetails,
              id_auction: auction.id_auction,
            }}
          />
        )}
        {/* </> */}
        {/* )}
        </FeatureFlag> */}
      </div>
      <PaymentSecureLabel />
    </div>
  );
};

function PaymentSecureLabel() {
  return (
    <div className={styles.label}>
      <span>
        <MdOutlineLock size={20} />
      </span>
      <span>Payment are secure and encrypted</span>
    </div>
  );
}
