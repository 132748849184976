import styles from "./index.module.css";
import { ReactNode } from "react";
import { ColId, TableColumnDef } from "../../types";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
import { cn } from "src/app/utils/cn";

type Props = {
  col: TableColumnDef<any>;
  sortBy: ColId | undefined;
  sortDir: "asc" | "desc" | undefined;
  handleClick: () => void;
  children: ReactNode;
};
export const SortableTdHeaderContent = ({
  col,
  sortBy,
  sortDir,
  handleClick,
  children,
}: Props) => {
  const isSorted = !!sortDir && !!sortBy && sortBy === col.id;
  const direction = sortDir || undefined;

  return (
    <div
      className={cn(styles.root, isSorted && styles.sorted)}
      onClick={handleClick}
    >
      {children}

      {!!isSorted && direction === "asc" && <IoMdArrowUp size={16} />}
      {!!isSorted && direction === "desc" && <IoMdArrowDown size={16} />}
    </div>
  );
};
