import { RadioGroup } from "@headlessui/react";
import styles from "./index.module.css";
import { useState } from "react";
import { Button } from "src/app/components/button";
import { useMyUser } from "src/app/hooks/useMyUser";
import { cn } from "src/app/utils/cn";
import {
  useSubscriptionPlans,
  useUpgradeSubscriptionMutation,
} from "src/app/api/subscription";
import { SubscriptionV1 } from "src/app/models/SubscriptionV1";
import { FaRegCircleDot } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa";
import { z } from "zod";
import { userSubscriptionSchemaV1 } from "src/app/models/UserV1";
import srcAmbassadorCard from "src/media/common/ambassador-card.png";
import srcCollectorCard from "src/media/common/collector-card.png";
import srcConnoisseurCard from "src/media/common/connoisseur-card.png";
import srcExplorerCard from "src/media/common/explorer-card.png";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { QKEY } from "src/app/api/QKEY";
import { ID_TIER } from "src/app/const";

export default function SubscriptionForm() {
  const user = useMyUser();
  const queryClient = useQueryClient();
  const isFetchingUser = useIsFetching({ queryKey: [QKEY.ME] });
  const alertPopup = useAlertPopup();
  const [isAnnual, setIsAnnual] = useState(
    user.subscription?.recurring_type === "annual",
  );
  const [selectedPlanId, setSelectedPlanId] = useState(
    user.subscription?.active ? user.subscription.id_subscription : undefined,
  );

  const {
    data: subscriptionPlansData,
    isLoading,
    refetch,
  } = useSubscriptionPlans({
    select: (data: SubscriptionV1[]) =>
      data.filter(
        (plan) =>
          plan.active &&
          plan.recurring_type === `${isAnnual ? "annual" : "monthly"}` &&
          !plan.hidden_frontend,
      ),
  });

  const upgradeSubscriptionMutation = useUpgradeSubscriptionMutation({
    onSuccess: (_, payload) => {
      alertPopup.success({
        title:
          payload.type === "downgrade"
            ? "Request sent"
            : "Subscription successfully updated",
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
    onError: (error) => {
      alertPopup.error({
        title: "Error on updating subscription",
        content: parseApiErrorV1(error),
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
  });

  if (isLoading)
    return (
      <div className={styles.planGroupRoot} style={{ padding: 20 }}>
        Loading data...
      </div>
    );

  if (!subscriptionPlansData)
    return (
      <div className={styles.planGroupRoot} style={{ padding: 20 }}>
        No subscription plans for this user.
      </div>
    );

  return (
    <>
      <div className={styles.planRecurrencyTabRoot}>
        <button
          type="button"
          className={cn(!isAnnual && styles.active)}
          onClick={() => setIsAnnual(false)}
        >
          Monthly
        </button>
        <button
          type="button"
          className={cn(isAnnual && styles.active)}
          onClick={() => setIsAnnual(true)}
        >
          Yearly
        </button>
      </div>

      <RadioGroup
        value={selectedPlanId}
        onChange={(planId) => {
          setSelectedPlanId(planId);
        }}
        className={cn(
          styles.planGroupRoot,
          !!isFetchingUser && styles.isFetching,
        )}
      >
        {subscriptionPlansData.map((plan: SubscriptionV1) => (
          <RadioGroup.Option
            value={plan.id_subscription}
            key={`option-${plan.id_subscription}`}
            className={styles.planOptionRoot}
            disabled={
              plan.id_subscription === user.subscription?.id_subscription
            }
          >
            <div className={styles.radioIcon}>
              {plan.id_subscription === user.subscription?.id_subscription ? (
                <FaRegCircleDot color="#00000030" size={20} />
              ) : plan.id_subscription === selectedPlanId ? (
                <FaRegCircleDot color="#783233" size={20} />
              ) : (
                <FaRegCircle color="#00000070" size={20} />
              )}
            </div>

            <div className={styles.cardImg}>
              <img
                src={getCardSrc(plan.name.toLowerCase())}
                alt={plan.name}
                style={{ display: "block", width: "100%", height: "auto" }}
              />
            </div>

            <div className={styles.planInfo}>
              <h4 className={styles.planTitle}>{plan.name}</h4>
              <p className={styles.planDescription}>{plan.description}</p>
            </div>

            <div className={styles.priceRoot}>
              <div className={styles.subscriptionType}>
                {getSubscriptionType(user.subscription, plan) ===
                "alreadySubscribed"
                  ? "Already Subscribed"
                  : getSubscriptionType(user.subscription, plan)}
              </div>
              <div className={styles.price}>
                E {plan.initial_fee}/
                {plan.id_subscription === 11
                  ? "Forever"
                  : isAnnual
                    ? "annual"
                    : "monthly"}
              </div>
              {+user.request_downgrade[0]?.id_subscription_to_change ===
                plan.id_subscription && (
                <small style={{ color: "red", fontSize: 10, fontWeight: 700 }}>
                  REQUEST IN PENDING
                </small>
              )}
            </div>
          </RadioGroup.Option>
        ))}
      </RadioGroup>

      <Button
        variant="contained"
        label="Subscribe Now"
        disabled={
          !!queryClient.isFetching({ queryKey: [QKEY.ME] }) ||
          user.id_customer_role === ID_TIER.FOUNDER ||
          user.id_customer_role === ID_TIER.PROFESSIONAL ||
          !selectedPlanId ||
          (user.subscription?.id_subscription === selectedPlanId &&
            user.subscription.status !== 0) ||
          !!user.checkUser.is_missing_card ||
          (user.subscription?.status && user.subscription.status >= 2) ||
          user.request_downgrade.length > 0 ||
          user.is_out_subscription === 1
        }
        onClick={() => {
          const selectedSubscription = subscriptionPlansData.find(
            (subscription) => subscription.id_subscription === selectedPlanId,
          );

          if (!selectedSubscription) return;
          const type = getSubscriptionType(
            user.subscription,
            selectedSubscription,
          );
          if (type === "alreadySubscribed") return;
          return alertPopup.prompt({
            title:
              type === "subscription"
                ? "Are you sure to confirm the membership plan?"
                : type === "downgrade"
                  ? "Do you want to request the plan downgrade?"
                  : "Confirm the plan upgrade",
            content:
              type === "upgrade"
                ? "By upgrading to the new plan, you will be charged"
                : "",
            actionButton: {
              label: "Confirm",
              onClick: () => {
                upgradeSubscriptionMutation.mutate({
                  subscription: selectedSubscription,
                  type,
                });
              },
            },
          });
        }}
      />
    </>
  );
}

function getSubscriptionType(
  currentSubscription: z.infer<typeof userSubscriptionSchemaV1>,
  nextSubscription: SubscriptionV1,
) {
  return currentSubscription?.active
    ? currentSubscription.id_subscription === nextSubscription.id_subscription
      ? "alreadySubscribed"
      : +currentSubscription.initial_fee < +nextSubscription.initial_fee
        ? "upgrade"
        : "downgrade"
    : "subscription";
}

function getCardSrc(nameImage: string) {
  if (nameImage === "collector") return srcCollectorCard;
  if (nameImage === "connoisseur") return srcConnoisseurCard;
  if (nameImage === "explorer") return srcExplorerCard;
  if (nameImage === "ambassador") return srcAmbassadorCard;
  return srcExplorerCard;
}
