import {
  SelectMultipleOption,
  SelectMultipleValues,
} from "src/app/components/form-elements/select-multiple/types";
import { z } from "zod";

export const FilterResponseSchema = z.record(
  z.string(),
  z.object({
    label: z.string(),
    filters: z.array(
      z.object({
        label: z.union([z.string(), z.number()]),
        total: z.number().optional(),
        value: z.union([z.string(), z.number()]).optional(),
      }),
    ),
  }),
);

export type FilterResponseSchema = z.infer<typeof FilterResponseSchema>;
const emptyResponse = {} satisfies FilterResponseSchema;

export const useSelectableFilters = ({
  filters,
  data = emptyResponse,
}: {
  filters: SelectMultipleValues;
  data: FilterResponseSchema | undefined;
}) => {
  // Groups available options by group name
  const groupedOptions = Object.entries(data).reduce(
    (allOptions, [serializedGroupName, groupOptions]) => {
      allOptions[serializedGroupName] = groupOptions.filters.map((filter) => ({
        label: filter.label,
        count: filter.total || 0,
        value:
          typeof filter.value !== "undefined" ? filter.value : filter.label,
        groupName: groupOptions.label,
        serializedGroupName,
      }));

      return allOptions;
    },
    {} as Record<string, SelectMultipleOption[]>,
  );

  const allowedFiltersGroupNames = Object.keys(data);
  const emptyValues: SelectMultipleValues = {};

  // filter out invalid values or forbidden options
  const filteredValues = Object.keys(data).length
    ? Object.entries(filters).reduce(
        (filters, [serializedGroupName, values]) => {
          if (
            Array.isArray(values) &&
            allowedFiltersGroupNames.includes(serializedGroupName)
          ) {
            // Only show allowed values
            const allowedFiltersValues = values.filter((value) =>
              groupedOptions[serializedGroupName].find(
                (el) => el.value === value,
              ),
            );
            if (allowedFiltersValues.length) {
              filters[serializedGroupName] = allowedFiltersValues;
            }
          }

          return filters;
        },
        emptyValues,
      )
    : emptyValues;

  return {
    values: filteredValues,
    options: groupedOptions,
  } as const;
};
