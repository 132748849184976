import styles from "./index.module.css";
import { A } from "../../../../components/a";
import { Button } from "../../../../components/button";
import { Spacer } from "../../../../components/spacer";
import bannerCrutrade from "src/media/banner-crutrade.png";
import { Img } from "../../../../components/img";
import { useLocalStorage } from "../state";
import { Dialog } from "src/app/components/dialog-2";
import { CRUTRADE_LINK } from "../constants";
import { useMyUser } from "src/app/hooks/useMyUser";
import { can } from "src/feature-flag/schema";

export const BannerDialog = () => {
  const [isHidden, setIsHidden] = useLocalStorage("hideBannerDialog");
  const user = useMyUser();
  const canAccessCrutrade = can.accessCrutrade(user);

  return (
    canAccessCrutrade &&
    !isHidden && (
      <Dialog
        onClosed={() => setIsHidden(true)}
        render={() => (
          <div className={styles.root}>
            <div className={styles.image}>
              <Img src={bannerCrutrade} alt="" lazyLoad={false} />
            </div>
            <div className={styles.description}>
              <h3 className={styles.title}>Start Trading your bottles</h3>
              <Spacer size={20} />
              <p className={styles.body}>
                Discover Crutrade, the premier platform for trading, buying, and
                selling fine wines. Effortlessly manage, send, receive, and
                store your bottles with the Crutrade Cellar.
              </p>
              <Spacer size={20} />
              <Button
                as={A}
                label="Discover Crutrade Today!"
                to={CRUTRADE_LINK}
                className={styles.button}
              />
            </div>
          </div>
        )}
      />
    )
  );
};
