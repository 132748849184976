import styles from "../index.module.css";
import { Fragment, useEffect, useState } from "react";
import { noop, range } from "lodash";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Checkbox } from "../../form-elements/checkbox";
import { TableNoDataBox } from "../_shared/table-no-data";
import { SortableTdHeaderContent } from "../_shared/sortable-td-header-content";
import { Pagination } from "../_shared/pagination";
import { toPx } from "src/app/utils/toPx";
import { cn } from "src/app/utils/cn";
import { Skeleton } from "../../skeleton";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { RowId, TDataBase, TableServerSideProps } from "../types";
import {
  defaultOptionsForPageSize,
  emptyArray,
  emptyPaginatedResponse,
  noRender,
  searchParamsSchema,
} from "../const";
import { LoadingSpinner } from "../_shared/loading-spinner";
import { getSortDir } from "../utils";
import { ArrowExpand } from "../_shared/arrow-expand";
import { RowDesktop } from "../_shared/row/row-desktop";
import { RowMobile } from "../_shared/row/row-mobile";

export function TableServerSide<TData extends TDataBase>({
  columns = emptyArray,
  data = emptyPaginatedResponse,
  enableExpansionForRow,
  enableSelectionForRow,
  fillMissingRows = true,
  getRowId,
  isFetching = false,
  isLoading = false,
  minCellHeightDesktop = "auto",
  minCellHeightMobile = "auto",
  noDataContent = "",
  noDataTitle = "",
  onChangeSelection = noop,
  optionsForPageSize = defaultOptionsForPageSize,
  renderSubRow = noRender,
  selection = [],
  urlPrefix,
  defaultExpanded,
}: TableServerSideProps<TData>) {
  const { items, page, total, total_pages } = data;
  const isMobile = useIsMobile();
  const initiallyExpanded = defaultExpanded ? items.map(getRowId) : [];
  const [expansion, setExpansion] = useState<RowId[]>(initiallyExpanded);
  const [searchParams, setSearchParams] = useTypedSearchParams({
    urlPrefix,
    schema: searchParamsSchema,
  });

  const { sortBy, sortDir, pageSize = 10 } = searchParams;

  const selectableData = items.filter(enableSelectionForRow || (() => true));
  const areAllRowsSelected =
    selection.length === selectableData.length && !isLoading;

  useEffect(() => {
    if (page > 0 && !isLoading && !items.length) {
      setSearchParams({ page: undefined });
      setExpansion([]);
    }
  }, [items, page, setSearchParams, isLoading]);

  function handleClickSelectRow(row: TData) {
    const selectionId = getRowId(row);
    if (selection.includes(selectionId)) {
      onChangeSelection(selection.filter((rowId) => rowId !== selectionId));
    } else {
      onChangeSelection([...selection, selectionId]);
    }
  }

  function handleClickExpandRow(row: TData) {
    const expansionId = getRowId(row);
    if (expansion.includes(expansionId)) {
      setExpansion(expansion.filter((rowId) => rowId !== expansionId));
    } else {
      setExpansion([...expansion, expansionId]);
    }
  }

  return (
    <div>
      <div>
        <table
          className={`${styles.table} ${
            isFetching && !isLoading ? styles.tableFetching : ""
          }`}
          aria-labelledby="tableTitle"
        >
          {!isMobile && (
            <thead className={styles.tableHead}>
              <tr>
                {/* Used for minCellHeightDesktop */}
                <td style={{ width: 0, padding: 0 }} />

                {!!enableExpansionForRow && (
                  <td className={styles.expansionCell} />
                )}

                {!!enableSelectionForRow && (
                  <td
                    className={cn(styles.tableHeadCell, styles.selectionCell)}
                  >
                    {isFetching && !isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <Checkbox
                        className={styles.checkbox}
                        disabled={
                          isLoading || !items.some(enableSelectionForRow)
                        }
                        checked={areAllRowsSelected}
                        onChange={() => {
                          areAllRowsSelected
                            ? onChangeSelection([])
                            : onChangeSelection(selectableData.map(getRowId));
                        }}
                        aria-label="Select All"
                      />
                    )}
                  </td>
                )}

                {columns.map((col, i) => (
                  <td
                    className={styles.tableHeadCell}
                    key={`header.table-${i}`}
                    align={col.alignHeaderDesktop}
                  >
                    {col.id ? (
                      <SortableTdHeaderContent
                        col={col}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        handleClick={() => {
                          setExpansion([]);
                          setSearchParams({
                            page: undefined,
                            sortBy: col.id,
                            sortDir: getSortDir(sortBy, sortDir, col),
                          });
                        }}
                      >
                        {col.header}
                      </SortableTdHeaderContent>
                    ) : (
                      col.header
                    )}
                  </td>
                ))}
              </tr>
            </thead>
          )}

          {/* DESKTOP Loading */}
          {!!isLoading && !isMobile && (
            <tbody>
              {range(0, pageSize).map((i) => (
                <tr key={`skeleton-row-${i}`}>
                  {/* Sets the min-height of the row */}
                  <td
                    className={styles.tableCell}
                    style={{
                      height: toPx(minCellHeightDesktop),
                      width: 0,
                      padding: 0,
                    }}
                  />

                  {!!enableExpansionForRow && (
                    <td className={cn(styles.tableCell, styles.expansionCell)}>
                      <ArrowExpand isExpanded={false} disabled />
                    </td>
                  )}

                  {!!enableSelectionForRow && (
                    <td className={cn(styles.tableCell, styles.selectionCell)}>
                      <Checkbox
                        className={styles.checkbox}
                        checked={false}
                        disabled
                      />
                    </td>
                  )}

                  {columns.map((col, j) => (
                    <td
                      key={`skeleton-cell-${j}-${i}`}
                      style={{
                        height: toPx(minCellHeightDesktop),
                        width: col.width || "auto",
                      }}
                      className={styles.tableCell}
                    >
                      <Skeleton.Text />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

          {/* DESKTOP Empty */}
          {!isLoading && !isMobile && !items.length && (
            <tbody>
              <tr>
                <td className={styles.tableCell} colSpan={100}>
                  <TableNoDataBox title={noDataTitle} content={noDataContent} />
                </td>
              </tr>
            </tbody>
          )}

          {/* DESKTOP Data */}
          {!isLoading && !isMobile && !!items.length && (
            <tbody>
              {items.map((row, index) => (
                <RowDesktop<TData>
                  key={`row-deskt-${getRowId(row) || index}-${index}`}
                  row={row}
                  columns={columns}
                  enableSelectionForRow={enableSelectionForRow}
                  isFetching={isFetching}
                  enableExpansionForRow={enableExpansionForRow}
                  minCellHeight={minCellHeightDesktop}
                  onClickSelect={handleClickSelectRow}
                  onClickExpand={handleClickExpandRow}
                  isSelected={selection.includes(getRowId(row))}
                  isExpanded={expansion.includes(getRowId(row))}
                  renderSubRow={renderSubRow}
                  getRowId={getRowId}
                />
              ))}
              {!!fillMissingRows &&
                Array(Math.max(0, pageSize - items.length))
                  .fill(0)
                  .map((_, i) => (
                    <tr
                      key={`empty-row-${i}`}
                      style={{ height: minCellHeightDesktop }}
                    />
                  ))}
            </tbody>
          )}

          {/* MOBILE Loading */}
          {!!isLoading && !!isMobile && (
            <tbody>
              {range(0, pageSize).map((i) => (
                <tr key={`skeleton-row-${i}`}>
                  <td className={styles.tableCell}>
                    <div className={styles.rowMobile}>
                      {!!enableSelectionForRow && (
                        <Fragment>
                          <div>
                            <Checkbox
                              className={styles.checkbox}
                              checked={false}
                              disabled
                            />
                          </div>
                          <div />
                        </Fragment>
                      )}

                      {columns.map((col, j) => (
                        <Fragment key={`skeleton-cell-${j}-${i}`}>
                          <div
                            className={styles.cellHeaderMobile}
                            style={{ minHeight: minCellHeightMobile }}
                          >
                            {col.header}
                          </div>
                          <div
                            style={{
                              minHeight: minCellHeightMobile,
                              height: minCellHeightMobile,
                            }}
                          >
                            <Skeleton.Text />
                          </div>
                        </Fragment>
                      ))}

                      {!!enableExpansionForRow && (
                        <div style={{ gridColumn: "1 / -1" }}>
                          <ArrowExpand
                            disabled={isFetching}
                            isExpanded={false}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {/* MOBILE Empty */}
          {!isLoading && !!isMobile && !items.length && (
            <tbody>
              <tr>
                <td className={styles.tableCell}>
                  <TableNoDataBox title={noDataTitle} content={noDataContent} />
                </td>
              </tr>
            </tbody>
          )}

          {/* MOBILE Data */}
          {!isLoading && !!isMobile && !!items.length && (
            <tbody>
              {items.map((row, index) => (
                <RowMobile<TData>
                  key={`row-mob-${getRowId(row) || index}-${index}`}
                  columns={columns}
                  row={row}
                  enableExpansionForRow={enableExpansionForRow}
                  enableSelectionForRow={enableSelectionForRow}
                  minCellHeight={minCellHeightMobile}
                  onClickSelect={handleClickSelectRow}
                  onClickExpand={handleClickExpandRow}
                  isSelected={selection.includes(getRowId(row))}
                  isFetching={isFetching}
                  renderSubRow={renderSubRow}
                  isExpanded={expansion.includes(getRowId(row))}
                  getRowId={getRowId}
                />
              ))}
            </tbody>
          )}
        </table>
      </div>
      {total_pages > 1 && (
        <Pagination
          optionsForPageSize={optionsForPageSize}
          total={total}
          pageSize={pageSize}
          page={Math.max(page - 1, 0)}
          onChangePage={(page) => {
            setExpansion([]);
            setSearchParams({ page: page + 1 });
          }}
          onChangePageSize={(pageSize) => setSearchParams({ pageSize })}
        />
      )}
    </div>
  );
}
