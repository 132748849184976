import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { PageLots } from "./lots";
import { PageBarrels } from "./barrels";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { NavLinks } from "src/app/components/nav-links";
import { path } from "src/app/routes/path";
import { PageExperiences } from "./experiences";
import { PAGE_TITLE } from "src/app/const";
import { PageBottles } from "./bottles";
import { PageStats } from "src/app/components/page-stats";
import { useMyAssetsStats } from "src/app/api/queries_to_rename_later";
import { Spacer } from "src/app/components/spacer";
import { BannerDialog } from "src/app/pages/my-assets/connection/banner-dialog";
import { DialogCrutradeConnect } from "./connection/dialog-connect";
import { BannerMessage } from "src/app/components/check-user-profile-issues/banner-message";

export const PageMyAssets = () => {
  const { pathname } = useLocation();

  if (pathname === path.myAssets.value()) {
    return <Redirect to={path.myAssets.lots()} push={false} />;
  }
  return <PageContent />;
};

const PageContent = () => {
  usePageTitle(PAGE_TITLE.MY_ASSETS());
  const statsQuery = useMyAssetsStats();

  return (
    <section className="collections-container physical-page-body">
      <BannerMessage
        title="Warning"
        icon="warning"
        message="Please be informed that our shipment services will be temporarily paused from Wednesday, January 8th to Monday, January 27th. Regular operations will resume on Monday, January 27th. Thank you for your understanding and cooperation"
      />

      <PageStats stats={statsQuery.data} />
      <Spacer size={24} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <NavLinks
          links={[
            { label: "Lots", to: path.myAssets.lots() },
            { label: "Barrels", to: path.myAssets.barrels() },
            // { label: "Bottles", to: path.myAssets.bottles() },
            { label: "Experiences", to: path.myAssets.experiences() },
          ]}
        />
        <DialogCrutradeConnect />
      </div>

      <div>
        <Switch>
          <Route path={path.myAssets.lots()} component={PageLots} />
          <Route path={path.myAssets.barrels()} component={PageBarrels} />
          <Route path={path.myAssets.bottles()} component={PageBottles} />
          <Route
            path={path.myAssets.experiences()}
            component={PageExperiences}
          />
        </Switch>
      </div>

      <BannerDialog />
      <Spacer size={16} />
    </section>
  );
};
