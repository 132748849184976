import { ApiLotV1 } from "src/app/models/LotV1";

import { useState } from "react";
import { StepPickCases, getTotalFees } from "./step-cases";
import { defineForm } from "crustack/form";
import { QuantityOption } from "./product-subrow";
import { StepCheckout } from "./step-checkout";
import { entriesOf } from "crustack/utils";
import { useExportToCrutradeMutation } from "src/app/api/crutrade";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { useQueryClient } from "@tanstack/react-query";
import { CRUTRADE_LINK } from "../constants";
import {
  getGroupedLotsProducts,
  getOptionForQuantity,
  getProdId,
  mapProductQuantitiesToActualProducts,
} from "./utilts";

type Props = {
  lots: ApiLotV1[] | undefined;
  onClose: () => void;
};

type ExportStep = "pick-bottles" | "checkout";

export const exportForm = defineForm<
  {
    products: {
      [idProduct: string]: QuantityOption | undefined;
    };
  },
  unknown,
  unknown
>();

export const DialogExport = ({ lots = [], onClose }: Props) => {
  const [currentStep, setStep] = useState<ExportStep>("pick-bottles");
  const queryClient = useQueryClient();
  const alertPopup = useAlertPopup();

  const exportMutation = useExportToCrutradeMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Request received",
        content: (
          <div>
            <span>Your request to import to Crutrade was received.</span>
            <br />
            <span>You can see the status updated in real time.</span>
          </div>
        ),
        actionButton: {
          label: "Go to Crutrade",
          linkTo: CRUTRADE_LINK,
        },
        closeButton: {
          label: "Close",
        },
        onClose: () => queryClient.invalidateQueries(),
      });
    },
    onError: (error) => {
      alertPopup.error({
        title: "Transfer error",
        content: error.error,
        onClose: () => queryClient.invalidateQueries(),
      });
    },
    onSettled: onClose,
  });

  return (
    <exportForm.Root
      initialValues={{
        products: getGroupedLotsProducts(...lots).reduce(
          (productList, prod) => ({
            ...productList,
            [getProdId(prod)]: getOptionForQuantity(prod.quantity, prod.size),
          }),
          {} as Record<string, QuantityOption>,
        ),
      }}
      onSubmit={(values) => {
        const selectedBottles = mapProductQuantitiesToActualProducts(
          lots,
          values.products,
        );

        exportMutation.mutate({ products: selectedBottles });
      }}
    >
      {(ctx) => {
        const prodQuantities = entriesOf(ctx.getFieldValue("products"))
          .map(([key, opt]) => [key, opt?.value])
          .filter(([, quantity]) => !!quantity)
          .reduce(
            (dict, [key, quantity]) => ({
              ...dict,
              [key as string]: quantity,
            }),
            {},
          );

        const totalFees = getTotalFees(lots, ctx.getFieldValue("products"));

        return currentStep === "checkout" ? (
          <StepCheckout
            lots={lots}
            products={prodQuantities}
            totalToPay={totalFees}
            onClose={onClose}
            onBack={() => setStep("pick-bottles")}
            onSubmit={() => ctx.tryToSubmit()}
          />
        ) : (
          <StepPickCases
            lots={lots}
            onClose={onClose}
            onNext={() => {
              ctx.validateForm();
              if (!ctx.hasErrors) {
                ctx.tryToSubmit();
                // TODO: enable for checkout with fees
                // setStep("checkout");
              }
            }}
          />
        );
      }}
    </exportForm.Root>
  );
};
