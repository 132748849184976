import styles from "./provenance-subrow.module.css";
import { DesktopCellContentText } from "src/app/components/table/_shared/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table/_shared/cell-content-mobile/cell-content-text";
import { TableCustomerSide } from "src/app/components/table/table-customer-side";
import { ApiLotV1 } from "src/app/models/LotV1";
import { ApiProduct } from "src/app/models/Product";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { CellContentBottleSize } from "./cell-content-bottle-size";
import { TableColumnDef } from "src/app/components/table/types";
import { A } from "src/app/components/a";

type Props = {
  row: ApiLotV1;
  isMobile: boolean;
};

export const ProvenanceSubRow = ({ row, isMobile }: Props) => {
  return (
    <tr>
      <td colSpan={100} className={styles.root}>
        <TableCustomerSide
          urlPrefix="ld."
          enablePagination={false}
          isFetching={false}
          isLoading={false}
          fillMissingRows={false}
          data={row.components}
          getRowId={(prod) => prod.id_product}
          minCellHeightDesktop="64px"
          noDataTitle=""
          noDataContent={<div></div>}
          columns={getColumns(isMobile)}
        />
      </td>
    </tr>
  );
};

function getColumns(isMobile: boolean) {
  const columns: Array<TableColumnDef<ApiProduct>> = [
    {
      header: "Bottle",
      id: "prod_case",
      cellDesktop: (prod) => (
        <DesktopCellContentText serif content={prod.nameProductComplete} />
      ),
      cellMobile: (prod) => (
        <MobileCellContentText serif content={prod.nameProductComplete} />
      ),
    },
    {
      header: "Btl. Size",
      id: "size",
      getSortingValue: (prod) => prod.size,
      cellDesktop: (prod) => <CellContentBottleSize product={prod} />,
    },
    {
      header: "Price per bottle",
      id: "prod_total",
      cellDesktop: (prod) => (
        <div>
          <span>{formatCurrency(prod.price)}</span>
        </div>
      ),
      cellMobile: (prod) => (
        <div>
          <span>{formatCurrency(prod.price)}</span>
        </div>
      ),
    },
    {
      header: "Serial number",
      id: "serial_number",
      cellDesktop: (prod) => <div>{prod.serial_number}</div>,
      cellMobile: (prod) => <div>{prod.serial_number}</div>,
    },
    {
      header: "Provenance Link",
      id: "provenance_link",
      cellDesktop: (prod) => (
        <div>
          <A
            target="_blank"
            to={`/provenance/${prod.serial_number ?? prod.sku}`}
          >
            View
          </A>
        </div>
      ),
      cellMobile: (prod) => (
        <div>
          <A
            target="_blank"
            to={`/provenance/${prod.serial_number ?? prod.sku}`}
          >
            View
          </A>
        </div>
      ),
    },
  ];

  if (!isMobile) {
    columns.unshift(
      {
        header: "",
        id: "spacer",
        width: 40,
        cellDesktop: () => <span></span>,
      },
      {
        header: "",
        width: 50,
        id: "spacer_selection",
        cellDesktop: () => <span></span>,
      },
    );
  }

  return columns;
}

export const getBottleName = (prod: ApiProduct) => {
  return [prod.vintage, prod.nameProductWithoutVintage, prod.builder.name]
    .filter(Boolean)
    .join(", ");
};
