import { useClearAllStores } from "../hooks/useClearAllStores";
import { STORAGE_KEY } from "../const";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { QKEY } from "./QKEY";
import { upfetch, upfetchV1, upfetchV3 } from "./upfetch";
import { UserV1, apiUserSchemaV1 } from "../models/UserV1";
import { useCountdown } from "../hooks/useCountdown";
import { useState } from "react";
import { z } from "zod";

const getMyUser = {
  queryKey: () => [QKEY.ME],
  queryFn: (token: string) => async () => {
    return upfetchV1<UserV1>({
      url: "users/GetUserByToken/",
      params: { token },
      schema: apiUserSchemaV1,
    });
  },
};

export const useMyUserQuery = (options: UseQueryOptions<UserV1> = {}) => {
  const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN) ?? "";
  const isEnabled = !!accessToken;
  return {
    isEnabled,
    ...useQuery({
      queryKey: getMyUser.queryKey(),
      queryFn: getMyUser.queryFn(accessToken),
      enabled: isEnabled,
      staleTime: Infinity,
      ...options,
    }),
  };
};

export const useLoginMutation = (
  options: UseMutationOptions<
    any,
    any,
    { email: string; password: string }
  > = {},
) => {
  const clearAllStores = useClearAllStores();

  return useMutation({
    mutationFn: async ({ email, password }) => {
      await clearAllStores();
      return upfetchV3({
        url: "auth/login/",
        method: "POST",
        body: { email, password },
        schema: z.object({
          access_token: z.string(),
          expires_in: z.number(),
          refresh_token: z.string(),
          token_type: z.union([z.literal("Bearer"), z.string()]),
        }),
        parseThrownResponse: async (res) => {
          try {
            const { result } = await res.json();
            return result.error || "Unexpected Error";
          } catch (e) {
            return "Unexpected Error";
          }
        },
      });
    },
    ...options,
  });
};

export const useLoginWithTokenMutation = (
  options: UseMutationOptions<any, any, { accessToken: string }> = {},
) => {
  const clearAllStores = useClearAllStores();

  return useMutation({
    mutationFn: async ({ accessToken }) => {
      await clearAllStores();
      localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
      return Promise.resolve();
    },
    ...options,
  });
};

export const useResendActivationEmailMutation = ({
  throttle,
}: {
  throttle: number;
}) => {
  const [waitUntil, setWaitUntil] = useState<Date | undefined>();
  const [remainingSec, setRemainingSec] = useState(0);

  useCountdown({
    unit: "seconds",
    isApiDate: false,
    to: waitUntil,
    onTick(remainingSec) {
      setRemainingSec(remainingSec);
    },
  });

  const mutation = useMutation<any, any, { id: number }>({
    mutationFn({ id }) {
      return upfetch({
        url: `users/customer/${id}/reSendConfirmationEmail/`,
      }).finally(() => {
        setWaitUntil(new Date(Date.now() + throttle));
        setRemainingSec(throttle / 1000);
      });
    },
  });

  return { remainingSec, ...mutation };
};

type ActivateAccountPayload = {
  code: string | number;
  person: string | number;
};

export const useActivateAccountMutation = (
  options: UseMutationOptions<any, any, ActivateAccountPayload> = {},
) => {
  return useMutation({
    mutationFn: (payload) => {
      return upfetchV1({
        url: "users/ActivateAccount/",
        method: "POST",
        body: payload,
        parseResponse: (res) => res.json(),
        schema: z.object({ code: z.number() }),
      });
    },
    ...options,
  });
};

export const useForgotPasswordMutation = (
  options: UseMutationOptions<
    any,
    any,
    { email: string; browser: string }
  > = {},
) => {
  return useMutation({
    mutationFn: (payload) => {
      return upfetchV1({
        url: "users/ForgotPassword/",
        method: "POST",
        body: payload,
        parseResponse: (res) => res.json(),
      });
    },
    ...options,
  });
};

type RequestPasswordChangePayload = {
  code: string | null;
  token: string | null;
  req: string | null;
};

const fetchRequestPasswordChange = async (
  payload: RequestPasswordChangePayload,
) => {
  const response = await upfetchV1({
    url: "users/RequestChangePassword/",
    method: "POST",
    body: payload,
  });
  return response;
};

export const useRequestPasswordChangeQuery = (
  payload: RequestPasswordChangePayload,
  options: UseQueryOptions<
    -1 | -2 | { id_user: number; email: string; password: string }
  > = {},
) => {
  return useQuery({
    queryKey: ["REQUEST_CHANGE_PASSWORD", payload],
    queryFn: () => fetchRequestPasswordChange(payload),
    ...options,
  });
};

export const useEditPasswordUserNotLoggedMutation = (
  options: UseMutationOptions<
    any,
    any,
    { code: string; token: string; req: string; new_password: string }
  > = {},
) => {
  return useMutation({
    mutationFn: (payload) => {
      return upfetchV1({
        url: "users/EditPasswordInformationUserNotLogged/",
        method: "POST",
        body: payload,
        parseResponse: (res) => res.json(),
      });
    },
    ...options,
  });
};

export const useEditPasswordMutation = (
  options: UseMutationOptions<
    any,
    any,
    { current_password: string; new_password: string }
  > = {},
) => {
  return useMutation({
    mutationFn: (payload) => {
      return upfetch({
        url: "users/editMyPassword/",
        method: "POST",
        body: payload,
        parseResponse: (res) => res.json(),
        schema: z.object({ code: z.number(), result: z.boolean() }),
      });
    },
    ...options,
  });
};
