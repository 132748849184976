import {
  DEFAULT_PAGE_SIZE,
  searchParamsSchema,
} from "src/app/components/table/const";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { SelectMultipleValues } from "../components/form-elements/select-multiple/types";

const DynamicFilterSchema = z.record(
  z.string(),
  z.union([
    z.string(), // required to support other params from SearchParamsSchema
    z.number(), // required to support other params from SearchParamsSchema
    z.array(z.union([z.string(), z.number(), z.boolean()])),
  ]),
);

const SearchParamsSchema = searchParamsSchema.extend({
  search: z.string().optional(),
  pageSize: z.number().optional(),
});

type DynamicFilterSchema = z.infer<typeof DynamicFilterSchema>;

export type SearchParamsSchema = z.infer<typeof SearchParamsSchema>;

const SearchFilterSchema = z.intersection(
  SearchParamsSchema,
  DynamicFilterSchema,
);

export const useFilters = () => {
  const [queryParams, setQueryParams] = useTypedSearchParams({
    schema: SearchFilterSchema,
  });
  const {
    search,
    page,
    pageSize = DEFAULT_PAGE_SIZE,
    sortBy,
    sortDir,
    ...filters
  } = queryParams;

  const dynamicFilters = Object.entries(filters).reduce(
    (values, [key, val]) => {
      if (Array.isArray(val)) {
        values[key] = val;
      }

      return values;
    },
    {} as SelectMultipleValues,
  );

  return {
    filters: dynamicFilters,
    setFilters: setQueryParams,
    search: {
      value: search,
      page,
      pageSize,
      sortBy,
      sortDir,
    },
  } as const;
};
