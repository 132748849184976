import { Spinner } from "react-bootstrap";

export const LoadingSpinner = () => {
  return (
    <Spinner
      animation="border"
      size="sm"
      style={{
        height: "18px",
        width: "18px",
        opacity: 0.5,
      }}
    />
  );
};
