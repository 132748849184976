import { z } from "zod";
import { ValueOf } from "../utils/types";

export const JOB_STATUS = {
  WAITING: "WAITING",
  FAILED: "FAILED",
  COMPLETED: "COMPLETED",
} as const;

export type JobStatus = ValueOf<typeof JOB_STATUS>;

export const apiCrutradeJobSchema = z.object({
  id: z.string(),
  data: z.object({
    id: z.number(),
    title: z.string(),
    serials_ids: z.array(z.number()),
    status: z.nativeEnum(JOB_STATUS),
  }),  
  updated_at: z.string(),
  read_at: z.string().nullable(),
});

const parsedJob = apiCrutradeJobSchema.omit({ updated_at: true });
export type ApiCrutradeJob = z.infer<typeof parsedJob> & {
  updated_at: Date;
};
