import { z } from "zod";
import { ApiCountry, Country, apiCountrySchema } from "./Country";

export const apiBuilderSchema = z.object({
  id: z.number(),
  id_builder: z.number(),
  active: z.number(),
  name: z.string(),
  winery_link_video: z.string().nullable(),
  winery_description: z.string().nullable(),
  created_date: z.string(),
  profile_pic: z.string().nullable(),
  region: z.string().nullable(),
  owner: z.any(),
  // id_country: z.number(),
  country: apiCountrySchema,
  // deleted: z.boolean(),
  gallery_horizontal_0: z.string().nullable().optional(),
  gallery_horizontal_1: z.string().nullable().optional(),
  gallery_horizontal_2: z.string().nullable().optional(),
  gallery_horizontal_3: z.string().nullable().optional(),
  gallery_horizontal_4: z.string().nullable().optional(),
  gallery_horizontal_5: z.string().nullable().optional(),
  gallery_vertical_0: z.string().nullable().optional(),
  gallery_vertical_1: z.string().nullable().optional(),
});

export type ApiBuilder = z.infer<typeof apiBuilderSchema>;

export class Builder {
  id: number;
  id_builder: number;
  active: number;
  name: string;
  winery_link_video: string | null;
  winery_description: string;
  created_date: string;
  profile_pic: string | null;
  region: string | null;
  owner: null;
  // id_country: number;
  country: ApiCountry;
  // deleted: boolean;
  gallery_horizontal_0: string | null;
  gallery_horizontal_1: string | null;
  gallery_horizontal_2: string | null;
  gallery_horizontal_3: string | null;
  gallery_horizontal_4: string | null;
  gallery_horizontal_5: string | null;
  gallery_vertical_0: string | null;
  gallery_vertical_1: string | null;

  constructor(apiBuilder: ApiBuilder) {
    this.id = apiBuilder.id;
    this.id_builder = apiBuilder.id_builder;
    this.active = apiBuilder.active;
    this.name = apiBuilder.name;
    this.winery_link_video = apiBuilder.winery_link_video;
    this.winery_description = apiBuilder.winery_description || "";
    this.created_date = apiBuilder.created_date;
    this.profile_pic = apiBuilder.profile_pic;
    this.region = apiBuilder.region;
    this.owner = apiBuilder.owner;
    // this.id_country = apiBuilder.id_country;
    this.country = new Country(apiBuilder.country);
    // this.deleted = apiBuilder.deleted;
    this.gallery_horizontal_0 = apiBuilder.gallery_horizontal_0 || null;
    this.gallery_horizontal_1 = apiBuilder.gallery_horizontal_1 || null;
    this.gallery_horizontal_2 = apiBuilder.gallery_horizontal_2 || null;
    this.gallery_horizontal_3 = apiBuilder.gallery_horizontal_3 || null;
    this.gallery_horizontal_4 = apiBuilder.gallery_horizontal_4 || null;
    this.gallery_horizontal_5 = apiBuilder.gallery_horizontal_5 || null;
    this.gallery_vertical_0 = apiBuilder.gallery_vertical_0 || null;
    this.gallery_vertical_1 = apiBuilder.gallery_vertical_1 || null;
  }
}
