import styles from "./index.module.css";
import { Button } from "src/app/components/button";
import { Input } from "src/app/components/form-elements/input";
import { Spacer } from "src/app/components/spacer";
import { BsArrowRight } from "react-icons/bs";
import { A } from "src/app/components/a";
import { CRUTRADE_LINK } from "../constants";
import { defineForm } from "crustack/form";
import { useOtpVerificationMutation } from "src/app/api/crutrade";

const otpForm = defineForm<{ code: string }, Error, unknown>();

export const InputOtpDialogStep = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const otpVerification = useOtpVerificationMutation({
    onSuccess,
  });
  return (
    <otpForm.Root
      initialValues={{ code: "" }}
      onSubmit={(data) => otpVerification.mutateAsync(data)}
    >
      {(ctx) => (
        <otpForm.Form style={{ width: "100%" }}>
          <div className={styles.body}>
            <Spacer size={32} />
            <p className={styles.subtitle}>
              If you did not generate a OTP code, please log in on your account,
              generate and copy it.
            </p>
            <Spacer size={8} />
            <A to={CRUTRADE_LINK} className={styles.ctaLink}>
              Go to my Crutrade account
              <BsArrowRight />
            </A>
            <Spacer size={32} />
            <otpForm.Field
              name="code"
              validate={(val) =>
                val.trim().length < 10
                  ? "You have entered a wrong code. Please try again."
                  : ctx.submission.error?.message
              }
            >
              <otpForm.Label
                children="Insert the OTP generated"
                className={styles.formLabel}
              />
              <Spacer size={8} />
              <Input
                placeholder="OTP"
                value={ctx.getFieldValue("code")}
                onChange={(e) => ctx.setFieldValue("code", e.target.value)}
                onBlur={() => ctx.setFieldTouched("code", true)}
              />
              <otpForm.ErrorMessage className={styles.errorMessage}>
                {(error) =>
                  error && (
                    <>
                      <Spacer size={10} />
                      <span>{error}</span>
                    </>
                  )
                }
              </otpForm.ErrorMessage>
            </otpForm.Field>
            <Spacer size={24} />
          </div>
          <Button
            label="Submit"
            className={styles.ctaButton}
            type="submit"
            isLoading={ctx.submission.isLoading}
          />
        </otpForm.Form>
      )}
    </otpForm.Root>
  );
};
