import { ApiLotV1 } from "src/app/models/LotV1";

export const getLotName = (lot: ApiLotV1, withVintage = true) => {
  let vintage = "";
  for (let i = 0; i < lot.components.length; i++) {
    const prod = lot.components[i];
    if (!vintage && !!withVintage) vintage = prod.vintage;
  }

  return [vintage, lot.auctions?.description || lot.description]
    .filter(Boolean)
    .join(", ");
};
