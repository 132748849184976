import { z } from "zod";
import { PaginatedResponse } from "./types";

export const defaultOptionsForPageSize = [
  { value: 5 },
  { value: 10 },
  { value: 25 },
];
export const DEFAULT_PAGE_SIZE = defaultOptionsForPageSize[1].value
export const emptyArray: any[] = [];
export const emptyPaginatedResponse: PaginatedResponse<any> = {
  items: [],
  page: 1,
  total: 0,
  total_pages: 1,
};
export const noRender = () => null;

export const searchParamsSchema = z.object({
  page: z.number().optional(),
  sortBy: z.union([z.string(), z.number()]).optional(),
  sortDir: z.union([z.literal("asc"), z.literal("desc")]).optional(),
  pageSize: z.number().optional(),
});
