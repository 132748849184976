import { z } from "zod";
import { Builder, apiBuilderSchema } from "./Builder";
import { ID_PRODUCT_CATEGORY, IdProductCategory } from "../const";
import { upperFirst } from "lodash";
import { formatPercentage } from "../utils/formatPercentage";

const apiProductCategorySchema = z.object({
  id: z.number(),
  id_father: z.number(),
  name: z.string(),
  is_virtual: z.union([z.literal(0), z.literal(1)]),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  parent: z.any(),
  root: z.nativeEnum(ID_PRODUCT_CATEGORY),
});

type ApiProductCategory = z.infer<typeof apiProductCategorySchema>;

class ProductCategory {
  id: number;
  id_father: number;
  name: string;
  is_virtual: boolean;
  created_at: string | null;
  updated_at: string | null;
  parent: Omit<ApiProductCategory, "parent"> | null;
  root: IdProductCategory;

  constructor(apiCategory: ApiProductCategory) {
    this.id = apiCategory.id;
    this.id_father = apiCategory.id_father;
    this.name = apiCategory.name;
    this.is_virtual = !!apiCategory.is_virtual;
    this.created_at = apiCategory.created_at;
    this.updated_at = apiCategory.updated_at;
    this.parent = apiCategory.parent;
    this.root = apiCategory.root;
  }
}

const apiProductMediaSchema = z.object({
  cover: z.string(),
  "3d": z.string(),
});

type ApiProductMedia = z.infer<typeof apiProductMediaSchema>;

class ProductMedia {
  cover: string;
  "3d": string;

  constructor(apiMedia: ApiProductMedia) {
    this.cover = apiMedia.cover;
    this["3d"] = apiMedia["3d"];
  }
}

export const apiProductSchema = z.object({
  "3d_model": z.string().nullable(),
  about: z.string(),
  // active: z.boolean(),
  age: z.number().nullable(),
  alcool_level: z.number(),
  appellation: z.string(),
  appellation_characteristic: z.string(),
  average_age_of_vines: z.string(),
  bottler: z.string().nullable(),
  bottling_year: z.number().nullable(),
  builder: apiBuilderSchema,
  category: apiProductCategorySchema,
  classification: z.string(),
  clos: z.string().nullable(),
  color: z.string(),
  cork: z.string(),
  cost_price: z.number(),
  created_date: z.string(),
  deleted: z.boolean(),
  description: z.string(),
  duration_of_aging: z.string(),
  edited_date: z.string().nullable(),
  // edition: z.string().nullable(),
  excise_code: z.string(),
  export_price: z.number(),
  id: z.number(),
  id_country: z.number().nullable(),
  id_serial: z.number(),
  wrapper_id: z.number().nullish(),
  id_product: z.number(),
  // id_product_mp: z.string(),
  investment: z.union([z.literal(0), z.literal(1), z.boolean()]).nullable(),
  // is_cost_price_percentage: z.number(),
  is_sparkling: z.boolean(),
  is_special_packing: z.boolean(),
  label: z.string(),
  level_of_wine: z.string(),
  link_video: z.string(),
  media: apiProductMediaSchema,
  nameProductComplete: z.string(),
  nameProductWithoutVintage: z.string(),
  organic: z.boolean(),
  person_signing: z.string(),
  photo_label: z.string(),
  price: z.number(),
  quantity: z.number(),
  region: z.string(),
  release_year: z.number().nullable(),
  signature_name: z.string().nullable(),
  signed: z.boolean(),
  size: z.number(),
  sku: z.string(),
  soil_composition: z.string(),
  stato: z.union([z.literal(0), z.literal(1)]),
  storage_condition: z.string().nullable(),
  sub_region: z.string().nullable(),
  supplier: z.any(),
  to_update_mp: z.boolean(),
  varietal: z.string(),
  vineyard_location: z.string(),
  vintage: z.string(),
  vintage_characteristics: z.string(),
  serial_number: z.string().nullish(),
});

export type ApiProduct = z.infer<typeof apiProductSchema>;

export class Product {
  about: string;
  // active: boolean;
  age: number | null;
  alcool_level: number;
  appellation: string;
  appellation_characteristic: string;
  average_age_of_vines: string;
  bottler: string | null;
  bottling_year: number | null;
  builder: Builder;
  category: ProductCategory;
  classification: string;
  clos: string;
  color: string;
  cork: string;
  cost_price: number;
  created_date: string;
  deleted: boolean;
  description: string;
  duration_of_aging: string;
  edited_date: string | null;
  // edition: string | null;
  excise_code: string;
  export_price: number;
  id: number;
  // id_country: number | null;
  id_product: number;
  id_serial: number;
  wrapper_id: number | null | undefined;
  // id_product_mp: string;
  investment: boolean;
  // is_cost_price_percentage: number;
  is_sparkling: boolean;
  is_special_packing: boolean;
  label: string;
  level_of_wine: string;
  link_video: string;
  media: ProductMedia;
  nameProductComplete: string;
  nameProductWithoutVintage: string;
  organic: boolean;
  person_signing: string;
  photo_label: string;
  price: number;
  quantity: number;
  region: string;
  release_year: number | null;
  signature_name: string | null;
  signed: boolean;
  size: number;
  sku: string;
  soil_composition: string;
  stato: boolean;
  storage_condition: string | null;
  sub_region: string | null;
  supplier: any;
  to_update_mp: boolean;
  varietal: string;
  vineyard_location: string;
  vintage: number;
  vintage_characteristics: string;

  constructor(apiProduct: ApiProduct) {
    this.about = apiProduct.about;
    // this.active = apiProduct.active;
    this.age = apiProduct.age;
    this.alcool_level = apiProduct.alcool_level;
    this.appellation = apiProduct.appellation;
    this.appellation_characteristic = apiProduct.appellation_characteristic;
    this.average_age_of_vines = apiProduct.average_age_of_vines;
    this.bottler = apiProduct.bottler;
    this.bottling_year = apiProduct.bottling_year;
    this.builder = new Builder(apiProduct.builder);
    this.category = new ProductCategory(apiProduct.category);
    this.classification = apiProduct.classification;
    this.clos = apiProduct.clos || "";
    this.color = apiProduct.color;
    this.cork = apiProduct.cork;
    this.cost_price = apiProduct.cost_price;
    this.created_date = apiProduct.created_date;
    this.deleted = apiProduct.deleted;
    this.description = apiProduct.description;
    this.duration_of_aging = apiProduct.duration_of_aging;
    this.edited_date = apiProduct.edited_date;
    // this.edition = apiProduct.edition;
    this.excise_code = apiProduct.excise_code;
    this.export_price = apiProduct.export_price;
    this.id = apiProduct.id;
    // this.id_country = apiProduct.id_country;
    this.id_product = apiProduct.id_product;
    this.id_serial = apiProduct.id_serial;
    this.wrapper_id = apiProduct.wrapper_id;
    // this.id_product_mp = apiProduct.id_product_mp;
    this.investment = !!apiProduct.investment;
    // this.is_cost_price_percentage = apiProduct.is_cost_price_percentage;
    this.is_sparkling = apiProduct.is_sparkling;
    this.is_special_packing = apiProduct.is_special_packing;
    this.label = apiProduct.label;
    this.level_of_wine = apiProduct.level_of_wine;
    this.link_video = apiProduct.link_video;
    this.media = apiProduct.media;
    this.nameProductComplete = apiProduct.nameProductComplete;
    this.nameProductWithoutVintage = apiProduct.nameProductWithoutVintage;
    this.organic = apiProduct.organic;
    this.person_signing = apiProduct.person_signing;
    this.photo_label = apiProduct.photo_label;
    this.price = apiProduct.price;
    this.quantity = apiProduct.quantity;
    this.region = apiProduct.region;
    this.release_year = apiProduct.release_year;
    this.signature_name = apiProduct.signature_name;
    this.signed = apiProduct.signed;
    this.size = apiProduct.size;
    this.sku = apiProduct.sku;
    this.soil_composition = apiProduct.soil_composition;
    this.stato = !!apiProduct.stato;
    this.storage_condition = apiProduct.storage_condition;
    this.sub_region = apiProduct.sub_region;
    this.supplier = apiProduct.supplier;
    this.to_update_mp = apiProduct.to_update_mp;
    this.varietal = apiProduct.varietal;
    this.vineyard_location = apiProduct.vineyard_location;
    this.vintage = +apiProduct.vintage;
    this.vintage_characteristics = apiProduct.vintage_characteristics;
  }

  get formattedProvenanceLocation() {
    const country = this.builder.country.name;
    const region = this.region || this.builder.region;
    return [country, region].filter(Boolean).join(", ");
  }

  get formattedAlcoholLevel() {
    return formatPercentage(this.alcool_level);
  }

  get formattedBottleSize() {
    return `${this.size}L`;
  }

  get formattedColor() {
    return upperFirst(this.color.toLocaleLowerCase());
  }

  get isSpirit() {
    return this.category.root === ID_PRODUCT_CATEGORY.SPIRIT;
  }

  get isWine() {
    return this.category.root === ID_PRODUCT_CATEGORY.WINE;
  }

  get formattedTypeOfWine() {
    if (this.isWine) {
      return this.excise_code === "W200" ? "Still" : "Sparkling";
    }
    if (this.isSpirit) {
      return upperFirst(this.category.name.toLocaleLowerCase());
    }
    console.error("Unknown product category");
    return "";
  }
}
