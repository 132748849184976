import styles from "./index.module.css";
import { useState } from "react";
import {
  ShadcnCarousel,
  ShadcnCarouselApi,
  ShadcnCarouselContent,
  ShadcnCarouselItem,
  useDotButton,
  usePrevNextButtons,
} from "src/app/components/shadcn-carousel";
import { cn } from "src/app/utils/cn";
import { Img } from "src/app/components/img";

import slideOne from "./slide-1.png";
import slideTwo from "./slide-2.png";
import slideThree from "./slide-3.png";
import slideFour from "./slide-4.png";
import { Spacer } from "src/app/components/spacer";
import { Button } from "src/app/components/button";

const slides = [
  <Slide
    key="banner-slide-1"
    imageSrc={slideOne}
    title="Increase your collection value and start to trade with Crutrade"
    description="Take the opportunity to create more value on your cellar and use the feature Crutrade to increase it."
  />,
  <Slide
    key="banner-slide-2"
    imageSrc={slideTwo}
    title="Send multiple cases"
    description="From your cellar, choose your items opportunities and send them to Crutrade."
  />,
  <Slide
    key="banner-slide-3"
    imageSrc={slideThree}
    title="Choose limited amount of bottles"
    description="Send specific quantity of bottles from each case."
  />,
  <Slide
    key="banner-slide-3"
    imageSrc={slideFour}
    title="Expand your horizons with Crurated"
    description="Not only your items! Choose the right way to give more value to your collection by importing bottles from Crutrade."
  />,
];

export const StepWalkthrough = ({ onClose }: { onClose: () => void }) => {
  const [carouselApi, setCarouselApi] = useState<ShadcnCarouselApi>();
  const { scrollSnaps, onDotButtonClick, selectedIndex } =
    useDotButton(carouselApi);

  const { prevBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(carouselApi);

  const lastSlide = selectedIndex === slides.length - 1;
  const onNext = () => {
    if (lastSlide) {
      return onClose();
    }

    onNextButtonClick();
  };

  return (
    <>
      <ShadcnCarousel setApi={setCarouselApi} gap={16}>
        <ShadcnCarouselContent>{slides}</ShadcnCarouselContent>
        <Spacer size={32} />
        {scrollSnaps.length > 1 && (
          <div className={styles.pagination}>
            {scrollSnaps.map((_, index) => (
              <button
                key={`ind-bef-${index}`}
                tabIndex={-1}
                className={cn(
                  styles.dotButton,
                  index === selectedIndex && styles.active,
                )}
                onClick={() => {
                  onDotButtonClick(index);
                }}
              >
                <div />
              </button>
            ))}
          </div>
        )}
      </ShadcnCarousel>
      <Spacer size={32} />
      <div className={styles.navigation}>
        <Button
          label="Back"
          variant="outlined"
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
        />
        <Button label={lastSlide ? "Let's start" : "Next"} onClick={onNext} />
      </div>
    </>
  );
};

function Slide({
  imageSrc,
  title,
  description,
}: {
  imageSrc: string;
  title: string;
  description: string;
}) {
  return (
    <ShadcnCarouselItem>
      <div className={styles.slide}>
        <h4>{title}</h4>
        <p>{description}</p>
        <Img
          src={imageSrc}
          alt={""}
          style={{ maxWidth: "100%" }}
          lazyLoad={false}
        />
      </div>
    </ShadcnCarouselItem>
  );
}
