import { ReactNode } from "react";
import { create } from "zustand";
import {
  AlertPopupError,
  AlertPopupPrompt,
  AlertPopupSuccess,
  WithActionButton,
} from ".";
import { AlertProps } from ".";

type Store = {
  success: (props: WithActionButton<AlertProps>) => void;
  error: (props: AlertProps) => void;
  prompt: (
    props:
      | {
          component: ReactNode;
        }
      | WithActionButton<AlertProps>,
  ) => void;
  close: () => void;
  currentPopup: {
    isOpen: boolean;
    component?: ReactNode;
  };
};

export const useAlertPopup = create<Store>((set) => ({
  success: (props) => {
    set(() => ({
      currentPopup: {
        isOpen: true,
        component: <AlertPopupSuccess {...props} />,
      },
    }));
  },
  error: (props) => {
    set(() => ({
      currentPopup: {
        isOpen: true,
        component: <AlertPopupError {...props} />,
      },
    }));
  },
  prompt: (props) => {
    set(() => ({
      currentPopup: {
        isOpen: true,
        component:
          "component" in props ? (
            props.component
          ) : (
            <AlertPopupPrompt {...props} />
          ),
      },
    }));
  },
  close: () =>
    set((state) => ({
      currentPopup: { ...state.currentPopup, isOpen: false },
    })),
  currentPopup: { isOpen: false },
}));
